/*
 * 업무구분: 보험증권재발행
 * 화 면 명: MSPNB007D
 * 화면설명: 증권재발행 신청결과확인
 * 접근권한: 모든사용자
 * 작 성 일: 2022.12.23
 * 작 성 자: 김진원
 */
<template>
  <ur-page-container class="msp" title="증권재발행" :show-title="true" type="subpage" action-type="none">
    <!-- 고정영역 -->
    <template #frame-header-fixed>
      <ur-box-container alignV="start"  direction="column" class="msp-pm-wrap">
        <!-- 완료메시지 -->
        <ur-box-container alignV="start" componentid="ur_box_container_002" direction="column" class="complete-msg ty1 bd-b-Ty1 mt50">
          <p class="msg-con fs22rem txt-center">
            <strong><span class="crTy-blue3">{{successCount}}</span>건의 증권 재발행<br> 신청이 완료되었습니다.</strong>
          </p>
        </ur-box-container>
        <!-- 완료메시지 //-->
     </ur-box-container>
    </template> 
    <!-- 고정영역 //-->

    <!-- 스크롤 List -->
    <ur-box-container direction="column" alignV="start" class="msp-pm-wrap">
      <!-- 재발행 신청리스트 -->
      <ur-box-container alignV="start" componentid="ur_box_container_001" direction="column" class="ns-list-area msp-list-area pb100">      
        <!-- list -->
        <template v-if="nBSecusRpblAplListSVO.length > 0">
          <ur-box-container alignV="start" componentid="ur_box_container_002" direction="column" class="ns-check-list type-2">
            <mo-list :list-data="nBSecusRpblAplListSVO">
              <template #list-item="{item, index}">
                <mo-list-item > 
                  <div class="list-item__contents">
                    <div class="list-item__contents__title"> 
                      <span class="name txtSkip">{{item.prdtNm}}</span> <!-- 상품명 -->
                    </div>
                    <div class="list-item__contents__info">
                      <span class="fs16rem fwb txtSkip maxW80">{{item.insrdNmMask}}</span><em>|</em> <!-- 피보험자 -->
                      <span class="crTy-bk7 fs14rem mr10">계약일 </span><span>{{item.contYmd}}</span> <!-- 계약일 -->
                    </div>
                    <div class="list-item__contents__info">
                      <span class="crTy-bk7 fs14rem mr10">계약번호 </span><span>{{item.contNoMask}}</span><em>|</em> <!-- 계약번호 -->
                      <span class="crTy-bk7 fs14rem mr10">상태 </span><span>{{item.contStatNm}}</span><em>|</em> <!-- 계약상태 -->
                      <span class="crTy-bk7 fs14rem mr10">보험료</span><span>{{item.smtotPrm}}</span> <!-- 보험료 -->
                    </div>
                    <div class="list-item__contents__info fexTy5 pt7">
                      <span class="crTy-bk7 fs14rem mr10">{{item.uiSecusCvancMthCdNm}}</span>
                      <mo-badge class="badge-sample-badge green sm" text="신청완료" shape="status" v-if="item.uiMesg === '신청완료'">{{item.uiMesg}}</mo-badge> 
                      <span class="ns-tool-tip display-inline-flex align-item-center" v-else-if="item.uiMesg != '-'">
                        <mo-badge class="badge-sample-badge red sm" text="신청불가" shape="status">{{item.uiMesg}}</mo-badge> <!-- 신청완료건 외에 오류건시 표시 //-->
                        <mo-icon :id="`location${index}`" icon="msp-tool-tip" class="ml5">tool-tip</mo-icon>
                        <mo-tooltip :target-id="`location${index}`" class="tool-tip-box w50vw">{{item.trtRsltCdNm}}</mo-tooltip>
                      </span>
                    </div>
                  </div>
                </mo-list-item>
              </template>
            </mo-list>   
          </ur-box-container>
        </template>
        <!-- list end -->
        <!-- list count 0 -->
        <template v-else>
          <ur-box-container alignV="start" componentid="" direction="column" class="no-data">
            <mo-list-item ref="expItem">
              <div class="list-item__contents">
                <div class="list-item__contents__info vh50">
                  <span class="ns-ftcr-gray"><p class="mt20">데이터가 존재하지 않습니다.</p></span>
                </div>
              </div>
            </mo-list-item>
          </ur-box-container>
        </template>
        <!-- list count 0 end -->
      </ur-box-container>
      <!-- 재발행 신청리스트 //-->
    </ur-box-container>
    <!-- 스크롤 List -->


    <!-- 하단 버튼 -->
    <ur-box-container alignV="start" componentid="ur_box_container_002" direction="column" class="ns-btn-relative">
      <div class="relative-div">
        <mo-button componentid="mo_button_001" color="primary" shape="border" size="medium" class="ns-btn-round blue" @click="fn_MovePage">완료</mo-button>
      </div>
    </ur-box-container>

  </ur-page-container>
</template>
<script>
  /***********************************************************************************
   * 공통 라이브러리 INCLUDE 영역	                                                   *
   ***********************************************************************************/
  export default {
    /***********************************************************************************
     * Vue 파일 속성 정의 영역	                                                       *
     ***********************************************************************************/
    name: "MSPNB007D",
    screenId: "MSPNB007D",
    components: {},
    props: {
      nBSecusRpblAplListSVO: {type: Array}, //보유계약리스트
    },
    /***********************************************************************************
     * Life Cycle 함수 정의 영역	                                                     *
     ***********************************************************************************/
    created() {
    },
    mounted() {},
    /***********************************************************************************
     * 화면변수 선언 영역	                                                             *
     ***********************************************************************************/
    data() {
      return {}
    },
    /***********************************************************************************
     * Computed 함수 정의 영역                                                         *
     ***********************************************************************************/
    computed: {
      successCount(){
        return this.$props.nBSecusRpblAplListSVO.filter(item => item.uiMesg === '신청완료').length
      }
    },
    /***********************************************************************************
     * 사용자 함수 정의 영역                                                           *
     ***********************************************************************************/
    methods: {
      fn_MovePage () {
        this.$router.go(-1)
      }
    }
  };
</script>
<style scoped>
</style>