/*
 * 업무구분: 보험증권재발행
 * 화 면 명: MSPNB003D
 * 화면설명: 보험증권재발행 > STEP.2 본인인증 & 본인동의
 * 접근권한: 모든사용자
 * 작 성 일: 2023.01.13
 * 작 성 자: 김진원
 */
<template>
  <mo-validate-watcher ref="vWatcher">
    <!-- 컨텐츠 영역 -->
    <ur-box-container direction="column" alignV="start" class="msp-pm-wrap">   
      <!-- 계약자 정보 -->
      <ur-box-container alignV="start" componentid="ur_box_container_001" direction="column" class="ns-list-area msp-list-area pb0">  
        <ur-box-container alignV="start" componentid="ur_box_container_002" direction="column" class="ns-check-list type-2">
         <div class="list-item w100 pb0"> <!-- 2023.01.31 수정 : pt40 클래스 삭제 //-->
          <div class="list-item__contents pb20 bd-b-Ty2">
            <div class="list-item__contents__title fexTy3"> <!-- 2023.01.31 수정> 'fexTy3' 추가 //-->
              <span class="name txtSkip maxW250 fexInt pr10">{{$props.custInsrdInfoVO.custNm}}</span>
              <mo-badge class="badge-sample-badge lightgreen sm" text="계약자" shape="status">계약자</mo-badge> 
            </div>
             <div class="list-item__contents__info">
              <div class="txt_box">
                <span class="crTy-bk1">{{age}}세</span><em>|</em><span class="crTy-bk1">{{rrn}}</span>
              </div>
            </div>
          </div>
         </div> 
        </ur-box-container>
      </ur-box-container>
      <!-- 계약자 정보 //-->

      <!-- 본인인증방식 -->
      <ur-box-container alignV="start" componentid="" direction="column" class="ns-add-area pt0 pl0 pr0 mb50"> 
        <ur-box-container alignV="start" componentid="" direction="column" class="pa13024 mb10">
          <div class="ns-check arrow mb30" >
            <mo-checkbox v-model="isCheckAgree" @input="fn_AgreeCnfrm('Open')" :disabled="isDisabled">본인확인 서비스 이용동의</mo-checkbox>
          </div>
          <ur-box-container alignV="start" componentid="" direction="column" class="ns-certify-area">
            <ur-box-container alignV="start" componentid="" direction="column" class="info-box mb20">
              <div class="verify-phone msp-verify-phone">
                <msp-bottom-select class="mr20" :items="telecomTypes" v-model="telecomType" underline placeholder="선택" bottom-title="통신사" :disabled="isTelecomTypeDisabled" closeBtn scrolling/>
                <!-- <msp-bottom-select ref="bottomSelect" :items="items1" underline class="mr20" placeholder="010" bottom-title="휴대폰 번호" closeBtn scrolling v-model="mobileNumber1"/> -->
                <mo-text-field underline placeholder="000 - 0000 - 0000" class="mb0 txt-center" v-model="mobileNumber" :mask="'phone'" disabled/>
              </div>
            </ur-box-container>
            <ur-box-container alignV="start" componentid="ur_box_container_006"  direction="column" class="ns-btn-relative-area pl0 pr0">
              <div class="ns-btn-relative-s w100">
                <div class="relative-div">
                  <mo-button color="primary" shape="border" size="medium" class="ns-btn-round white" @click="fn_ReqAuthNumber" :disabled="isReqAuthNumberBtnDisabled">{{reqAuthBtnTitle}}</mo-button>
                </div>
              </div>
            </ur-box-container>
            <!-- 인증번호 전송 클릭시 표출됨 => 인증확인 완료시 : 클래스값 "success"표시, 인증 오류시: 클래스값 "error"표시 -->
            <div class="ns-certify-sed" :class="authMessage" v-show="isAuthBoxShow">
              <div class="ns-certify-sed-code mb2sam0">
                <div class="left">
                  <mo-text-field  type="number" class="form-input-name" ref="error" :rules="validateRuleAuthNumber" v-model="authNumber" mask="######" underline placeholder="인증번호를 입력해주세요." :disabled="isAuthNumberDisabled"/>
                  <span class="time">{{countDown}}</span>
                </div>
              </div>
              <ur-box-container alignV="start" componentid="ur_box_container_006"  direction="column" class="ns-btn-relative-area pl0 pr0">
                <div class="ns-btn-relative-s w100">
                  <div class="relative-div">
                    <mo-button color="primary" shape="border" size="medium" class="ns-btn-round white" @click="fn_PrcsMoblAthntCnf" :disabled="isAuthConfirmBtnDisabled">인증확인</mo-button>
                  </div>
                </div>
              </ur-box-container>
              <div class="ns-certify-sed-txt">
                <div class="txt-success"><span class="ico"></span>인증 완료되었습니다.</div>
                <div class="txt-error">인증번호가 다릅니다.</div>
              </div>
            </div>
          </ur-box-container>    
        </ur-box-container>   
        <ur-box-container alignV="start" componentid="ur_box_container_001" direction="column" class="ns-agreeArea2 bd-T-Ty2">
          <ur-box-container alignV="start" componentid="ur_box_container_002" direction="column">
            <div class="agree-box pa2024">
              <strong class="ns-title fs22rem">본인인증 동의</strong>
              <p class="con-title3 fwm pt30 pb5">소비자 권익보호에 관한 사항</p>
              <p class="fwm">최소한의 정보처리 및 동의거부에 관한 안내 </p>
              <p class="agree-txt">
                정보동의서 금융거래 관련 상담 및 처리목적 달성에 부합하는 <strong>최소한의 정보만 수집·이용</strong> 하며,
                <strong>본 동의를 거부</strong> 하시는 경우에는 금융거래관련 상담 및 처리에 관한 정상적인 서비스 제공이 불가능할 수 있습니다.
                <strong>신용등급에 영향을 주지 않습니다.</strong>
              </p>
            </div>
            <div class="chk-box pt15">
              <div class="chk-title">
                보험계약 유지 ·관리 관련 업무를 위한 개인(신용)정보 등의 처리 동의서
              </div>
            <div class="ns-check flex">
              <mo-checkbox v-model="agreeCbAll" @input="fn_AllAgree">전체동의</mo-checkbox>
            </div>
            </div>
            <mo-list-item class="agree-list accordion-type2 maxH200"> <!-- expand-only-btn title-first 뒤에 들어가있는데 차이점은? -->
              <msp-expand btn-area-first ref="agreeExpand1" expanded title-first class="mo-list-expand" btn-area-class="fexTy3-1 mb20">
                <template #title>
                  <div class="list-item__contents">
                    <div class="list-item__contents__title">
                      <div class="ns-check">
                        <mo-checkbox v-model="agreeCb1">1. 개인(신용)정보 등의 수집·이용에 관한 사항</mo-checkbox>
                      </div>
                    </div>
                  </div>
                </template>
                <template #btn>
                  <mo-button class="link-arrow down"></mo-button>
                </template>
                <template #content>
                  <div class="list-txtBox">
                    <p class="pb30">
                      당사 및 당사 업무수탁자가 「개인정보보호법」 및 「신용정보의 이용 및 보호에 관한 법률」에 따라 본 계약과 관련하여 귀하의 개인(신용)정보 등을 다음과 같이 수집·이용 하고자 합니다.
                    </p>
                    <strong>■  개인(신용)정보의 수집 · 이용 목적</strong>
                    <ul class="txt-indent">
                      <li class="pl15 pb10">- 보험계약 유지 · 관리 관련 업무 (보험증권재교부 및 이를 위한 계약자 본인 인증, 개인정보 확인 및 변경 등)</li>
                    </ul>
                  </div>
                  <div class="list-txtBox">
                    <strong>■  수집 · 이용하는 자</strong>
                    <ul class="txt-indent">
                      <li>- 당사(삼성생명), 당사로부터 보험계약 유지·관리 업무 등에 필요한 업무를 위탁받은 자(삼성생명 컨설턴트, 보험대리점, 보험중개사, 고객안내 발송 대행업체, 전산회사 등)</li>
                    </ul>
                  </div>
                  <div class="list-txtBox">
                    <strong>■  당사 등이 수집 · 이용할 개인(신용)정보의 내용</strong>
                    <ul class="txt-indent">
                      <li>- 개인식별정보(성명, 주소, 연락처 등), 고유식별정보(주민등록번호 등), 보험거래관련 정보(보험가입정보 등)</li>
                    </ul>
                  </div>
                  <div class="list-txtBox last">
                    <strong>■  개인(신용)정보 등의 보유 · 이용기간</strong>
                    <ul class="txt-indent">
                      <li>- 수집 · 이용 동의일로부터 거래종료 후 5년까지 (단, 거래종료 후 5년이 경과한 후에는 보험금 지급, 금융사고 조사, 보험사기 방지·적발, 민원처리, 법령상 의무이행을 위한 경우에 한하여 보유·이용하며, 별도보관)</li>
                    </ul>
                  </div>
                </template>
              </msp-expand>
            </mo-list-item>
            <mo-list-item class="agree-list accordion-type2 maxH200"> <!-- expand-only-btn -->
              <msp-expand btn-area-first ref="agreeExpand2" expanded title-first class="mo-list-expand" btn-area-class="fexTy3-1 mb20">
                <template #title>
                  <div class="list-item__contents">
                    <div class="list-item__contents__title">
                      <div class="ns-check">
                        <mo-checkbox v-model="agreeCb2">2. 개인(신용)정보 등의 제공에 관한 사항</mo-checkbox>
                      </div>
                    </div>
                  </div>
                </template>
                <template #btn>
                  <mo-button class="link-arrow down"></mo-button>
                </template>
                <template #content>
                  <div class="list-txtBox">
                    <p class="pb30">
                      당사 및 당사 업무수탁자는 「개인정보보호법」 및 「신용정보의 이용 및 보호에 관한 법률」에 따라 본 계약과 관련하여 귀하의 개인(신용)정보 등을 다음과 같이 수집 · 이용 하고자 합니다.
                    </p>
                    <strong>■  개인(신용)정보를 제공받는 자</strong>
                    <ul class="txt-indent">
                      <li>- (주)나이스평가정보 및 당사로부터 보험계약유지·관리 업무 등에 필요한 업무를 위탁받은자(삼성생명컨설턴트, 보험대리점, 보험중개사, 고객안내장 인쇄 및 발송업체, 전산회사 등)</li>
                    </ul>
                  </div>
                  <div class="list-txtBox">
                    <strong>■  개인(신용)정보 등의 정보를 제공받는 자의 목적</strong>
                    <ul class="txt-indent">
                      <li>- 보험계약 유지 · 관리 관련 업무(보험증권재교부 및 이를 위한 계약자 본인 인증, 개인정보 확인 및 변경 등)</li>
                    </ul>
                  </div>
                  <div class="list-txtBox">
                    <strong>■  제공할 개인(신용)정보의 내용</strong>
                    <ul class="txt-indent">
                      <li>- 개인식별정보(성명, 주소, 연락처 등), 고유식별정보(주민등록번호 등), 보험거래 관련 정보(보험가입정보 등)</li>
                    </ul>
                  </div>
                  <div class="list-txtBox last">
                    <strong>■  제공받는 자의 개인(신용)정보 보유 · 이용기간</strong>
                    <ul class="txt-indent">
                      <li>- 제공 동의일로부터 거래종료 후 5년까지(단, 거래종료 후 5년이 경과한 후에는 보험금 지급, 금융사고조사, 보험사기 방지·적발, 민원처리, 법령상 의무이행을 위한 경우에 한하여 보유 · 이용하며 별도보관)</li>
                    </ul>
                  </div>
                </template>
              </msp-expand>
            </mo-list-item>
            <mo-list-item class="agree-list accordion-type2 maxH200">
              <msp-expand btn-area-first ref="agreeExpand3" expanded title-first class="mo-list-expand" btn-area-class="fexTy3-1 mb20">
                <template #title>
                  <div class="list-item__contents">
                    <div class="list-item__contents__title">
                      <div class="ns-check">
                        <mo-checkbox v-model="agreeCb3">3. 고유식별정보의 처리에 관한 사항</mo-checkbox>
                      </div>
                    </div>
                  </div>
                </template>
                <template #btn>
                  <mo-button class="link-arrow down"></mo-button>
                </template>
                <template #content>
                  <div class="list-txtBox last">
                    <p>
                      당사 및 당사 업무수탁자는 「개인정보보호법」 및 「신용정보의 이용 및 보호에 관한 법률」에 따라 상기의 개인(신용)정보 등에 대한 개별 동의 사항에 대하여, 다음과 같이 귀하의 고유식별정보 (주민등록번호 등)를 처리(수집·이용, 제공 등) 하고자 합니다.
                    </p>
                  </div>
                </template>
              </msp-expand>
            </mo-list-item>
          </ur-box-container>
        </ur-box-container>
      </ur-box-container>
      <!-- 본인인증방식 //-->
     
      <!-- 하단 버튼영역 -->
      <ur-box-container alignV="start" componentid="ur_box_container_002" direction="column" class="ns-btn-relative">
        <div class="relative-div">
          <mo-button componentid="mo_button_002" color="primary" shape="border" size="medium" class="ns-btn-round white" @click="fn_pre()">이전</mo-button>
          <mo-button componentid="mo_button_002" color="normal" shape="border" size="medium" class="ns-btn-round blue" :disabled="isNextBtnDisabled" @click="$emit('next', authSuccessTime)">다음</mo-button>
        </div>
      </ur-box-container>
      <!-- 하단 버튼영역 //-->
      
      <!-- 이용동의 안내 bottomSheet -->
      <mo-bottom-sheet ref="agreeCnfrmSheet" :close-btn="false" class="ns-bottom-sheet closebtn" @before-close="fn_SheetClose">
        <template v-slot:title>
          본인확인서비스 이용동의
          <div class="ns-btn-close" @click="fn_AgreeCnfrm('CloseBtn')" name="닫기"></div>
        </template>
        <div class="content-area">
          <ul class="terms-list-area pb36">
            <li>개인정보 수집/이용 동의</li>
            <li>고유식별정보 처리 동의</li>
            <li>통신사 이용약관 동의</li>
            <li>서비스 이용약관 동의</li>
            <li>개인정보 제 3자 제공 동의</li>
          </ul>
        </div>

        <template v-slot:action>
          <ur-box-container alignV="start" componentid="" direction="column" class="ns-btn-relative-bs noshadow">
            <div class="relative-div">
              <mo-button componentid="mo_button_005" color="primary" shape="primary" size="border" class="ns-btn-round white" @click="fn_AgreeCnfrm('Confirm')">약관 확인</mo-button>
              <mo-button componentid="mo_button_006" color="primary" shape="primary" size="border" class="ns-btn-round blue" @click="fn_AgreeCnfrm('AllConfirm')">전체 약관 동의</mo-button>
            </div>
          </ur-box-container>
        </template>
      </mo-bottom-sheet>
      <!-- 이용동의 안내 bottomSheet -->
      
    </ur-box-container>
    <!-- 컨텐츠영역  //-->
  </mo-validate-watcher>
</template>
<script>
/***********************************************************************************
 * 공통 라이브러리 INCLUDE 영역	                                                   *
 ***********************************************************************************/
//import moment from 'moment'
import MspBottomSelect from '@/ui/_cmm/components/MspBottomSelect.vue'
import MSPBottomAlert from '@/ui/_cmm/components/MSPBottomAlert'
import moment from 'moment'

//const COUNTER = '00:05'
const COUNTER = '07:00'

export default {
  /***********************************************************************************
   * Vue 파일 속성 정의 영역	                                                       *
   ***********************************************************************************/
  name: "MSPNB003D",
  screenId: "MSPNB003D",
  components: {
    MspBottomSelect
  },
  /***********************************************************************************
   * Life Cycle 함수 정의 영역	                                                     *
   ***********************************************************************************/
  created() {
    const custInfo = this.$props.custInsrdInfoVO

    if (custInfo === undefined) {
      this.$props.custInsrdInfoVO = {}

    } else {

      if ( this.isNotEmpty(custInfo.rrn) ) {
        //(900101)(2)****** : $1-$2******
        //(^\d{6}) : 숫자로 시작하는 6자리 $1
        //(\d{1}) : 숫자 1자리 $2
        //.* : 나머지
        this.rrn = custInfo.rrn.replace(/(^\d{6})(\d{1}).*/, '$1-$2******')
      }

      this.age = this.$pmUtil.fn_GetAge(this.$bizUtil, custInfo.knbFrno) // 연령 셋팅.
      this.mobileNumber = custInfo.celno
    }
  },
  mounted() {},
  props:{
    custInsrdInfoVO: {type: Object}
  },
  /***********************************************************************************
   * 화면변수 선언 영역	                                                             *
   ***********************************************************************************/
  data() {
    return {
      validateRuleAuthNumber : [
        (v) => !!v || '인증번호를 입력하세요'
      ],
      age : '', // step1에서 조회된 고객의 연령
      rrn : '', // step1에서 조회된 고객의 주민번호
      isCheckAgree : false, // 본인확인 서비스 이용 동의
      isDisabled : false, // 본인확인 서비스 이용 동의 활성 여부
      isTelecomTypeDisabled : false, //통신사 활성 여부
      certVnoUrl : 'https://cert.vno.co.kr/app/agree/agree_main.jsp', // 이통사 본인확인 서비스 이용에 따른 동의 나이스 링크 URL
      mobileNumber : '', // step1에서 조회된 고객의 핸드폰 번호
      reqAuthBtnTitle : '인증번호 전송', // 인증번호가 전송되면 '인증번호 재전송' 으로 변경됨.
      authNumber : '', // 인증번호
      authMessage : '', // 인증확인 결과 메시지
      authSuccessTime: '', // 인증성공시간
      isReqAuthNumberBtnDisabled : false, // 인증번호 전송버튼 활성 여부
      isAuthNumberDisabled : false, // 인증번호 입력란 활성 여부 (인증번호전송 : 활성, 인증확인 : 비활, 인증확인실패 : 활성)
      isAuthConfirmBtnDisabled : false, // 인증번호 확인 버튼 활성 여부
      isNextBtnDisabled : true, // 다음 버튼 활성 여부
      isAuthBoxShow: false, //인증번호 입력 및 확인 box
      countDown : '',
      interval : null, // 인증번호 타이머 인터벌
      moblAthntCd : '', // 모바일인증코드
      moblAthntPswd : '', // 모바일인증비밀번호
      respUnqNo : '', // 응답고유번호
      reqUnqNo : '', // 요청고유번호
      aprvNoErrCnt : 0, // 인증번호 인증 실패 횟 수
      agreeCbAll : false, // 전체선택
      agreeCb1 : false, // 본인동의 체크1
      agreeCb2 : false, // 본인동의 체크2
      agreeCb3 : false, // 본인동의 체크3
      telecomType : 'SKT', // 통신사 선택값
      telecomTypes : [{value: 'SKT',text: 'SKT'}
                     ,{value: 'KT',text: 'KT'}
                     ,{value: 'LGT',text: 'LG U+'}
                     ,{value: 'SKTM',text: 'SKT(알뜰폰)'}
                     ,{value: 'KTM',text: 'KT(알뜰폰)'}
                     ,{value: 'LGTM',text: 'LG U+(알뜰폰)'}] //통신사 select box 목록
    };
  },
  
  /***********************************************************************************
   * Watch 함수 정의 영역                                                         *
   ***********************************************************************************/
  watch: {
    // true : check, false : uncheck
    agreeCb1(isChecked) {
      if( isChecked && !this.$refs.agreeExpand1._props.expanded){
        this.$refs.agreeExpand1.toggleExpand()
      } else if(this.$refs.agreeExpand1._props.expanded) {
        this.$refs.agreeExpand1.toggleExpand()
      }
      this.fn_FinalCheck()
    },
    agreeCb2(isChecked) {
      if( isChecked && !this.$refs.agreeExpand2._props.expanded){
        this.$refs.agreeExpand2.toggleExpand()
      } else if(this.$refs.agreeExpand2._props.expanded) {
        this.$refs.agreeExpand2.toggleExpand()
      }
      this.fn_FinalCheck()
    },
    agreeCb3(isChecked) {
      if( isChecked && !this.$refs.agreeExpand3._props.expanded){
        this.$refs.agreeExpand3.toggleExpand()
      } else if(this.$refs.agreeExpand3._props.expanded) {
        this.$refs.agreeExpand3.toggleExpand()
      }
      this.fn_FinalCheck()
    }
  },
  /***********************************************************************************
   * 사용자 함수 정의 영역                                                           *
   ***********************************************************************************/
  methods: {
    /******************************************************************************
    * Function명 : fn_pre
    * 설명       : 이전 버튼 클릭
    ******************************************************************************/
    fn_pre() {
      let lv_Vm = this

      if( this.isNotEmpty(this.authSuccessTime) ){
        let bottomAlert = this.$bottomModal.open(MSPBottomAlert, {
            properties: {
              //single: true,
              title: "이전화면 이동 확인",
              content: '본인확인 정보가 초기화 됩니다. 이전 화면으로 이동 하시겠습니까?',
              title_pos_btn: "예",
              title_neg_btn: "아니오"
            },
            listeners: {
              onPopupConfirm: () => {
                lv_Vm.$bottomModal.close(bottomAlert)
                lv_Vm.$emit('pre')
              },
              onPopupClose: () => {
                lv_Vm.$bottomModal.close(bottomAlert)
              }
            }
          })
      } else {
        lv_Vm.$emit('pre')
      }
    },
    
    /******************************************************************************
    * Function명 : fn_ReqAuthNumber
    * 설명       : 인증번호 전송
    ******************************************************************************/
    fn_ReqAuthNumber() {
      //본인확인 서비스 이용동의 체크 확인
      if(!this.isCheckAgree){
        this.fn_ConfirmOpen()
        return
      }

      if( this.interval ){
        window.clearInterval(this.interval) // 재요청인 경우 기존 타이머 종료
      }
      
      this.isAuthBoxShow = true // 인증번호, 타이머, 인증확인 버튼 display
      this.isAuthNumberDisabled = false // 인증번호 입력필드 비활성화
      this.isAuthConfirmBtnDisabled = false // 인증확인 버튼 비활성화
      this.$refs.vWatcher.clearValidation() // 인증번호 확인 오류 메시지 클리어

      const custInfo = this.$props.custInsrdInfoVO //고객정보

      let pParams = {
                      custId: this.$bizUtil.isEmpty(custInfo.custId) ? '' : custInfo.custId, // 고객ID
                      custNm: this.$bizUtil.isEmpty(custInfo.custNm) ? '' : custInfo.custNm, // 고객명
                      knbFrno: this.$bizUtil.isEmpty(custInfo.knbFrno) ? '' : custInfo.knbFrno, // 주민번호 앞자리
                      knbBknoCipher: this.$bizUtil.isEmpty(custInfo.knbBknoCipher) ? '' : custInfo.knbBknoCipher, // 주민번호 뒷자리 암호화
                      selfAthntUsScCd: '01', // 본인인증용 구분코드 01 : 개인정보활용동의, 02:전자서명 본인인증(전화번호 수정불가), 03: 본인인증 AS_IS(1:01, 2:02)
                      cmmuScCd: this.telecomType, // 통신사
                      celno: this.mobileNumber, // 핸드폰 번호
                      knb: 'NB', // 업무구분코드
                      rrn: this.$bizUtil.isEmpty(custInfo.knbBkno) ? '' : (custInfo.knbFrno + custInfo.knbBkno)
                    }

      let lv_Vm = this

      this.post(lv_Vm, pParams, 'txTSSFN70S1', 'S')
        .then(function (response) {

          if (response.body !== null && response.body !== '' && response.body.rsltCd === '0000') {
            
            let lv_TmpResult = response.body

            // 정상요청 된 경우
            if (lv_TmpResult.rsltCd === '0000') {
              lv_Vm.moblAthntCd = lv_TmpResult.moblAthntCd // 모바일인증코드
              lv_Vm.moblAthntPswd = lv_TmpResult.moblAthntPswd // 모바일인증비밀번호
              lv_Vm.respUnqNo = lv_TmpResult.respUnqNo // 응답고유번호
              lv_Vm.reqUnqNo = lv_TmpResult.reqUnqNo // 요청고유번호

              if( lv_Vm.interval !== null ){
                window.clearInterval(lv_Vm.interval)
              }

              lv_Vm.reqAuthBtnTitle = '인증번호 재요청'

              lv_Vm.fn_StartCounter()
            }

          } else { // 정상처리가 아닌경우
            lv_Vm.getStore('confirm').dispatch('ADD', response.msgComm.msgDesc)
          }

        })
        .catch(function (error) {
          window.vue.error(error)
        })
    },
    
    /******************************************************************************
    * Function명 : fn_PrcsMoblAthntCnf
    * 설명       : 핸드폰 인증번호 확인
    ******************************************************************************/
    fn_PrcsMoblAthntCnf() {
      if(!this.$refs.vWatcher.isValid()){
        return
      } else {
        this.$refs.vWatcher.clearValidation()
      }

      this.aprvNoErrCnt++ // 인증번호 전송 횟수 체크 (3회까지만 허용됨)

      const custInfo = this.$props.custInsrdInfoVO //고객정보

      let pParams = {
                      custId: this.$bizUtil.isEmpty(custInfo.custId) ? '' : custInfo.custId, // 고객ID
                      custNm: this.$bizUtil.isEmpty(custInfo.custNm) ? '' : custInfo.custNm, // 고객명
                      knbFrno: this.$bizUtil.isEmpty(custInfo.knbFrno) ? '' : custInfo.knbFrno, // 주민번호 앞자리
                      knbBknoCipher: this.$bizUtil.isEmpty(custInfo.knbBknoCipher) ? '' : custInfo.knbBknoCipher, // 주민번호 뒷자리 암호화
                      selfAthntUsScCd: '01', // 본인인증용 구분코드 01 : 개인정보활용동의, 02:전자서명 본인인증(전화번호 수정불가), 03: 본인인증 AS_IS(1:01, 2:02)
                      celno: this.mobileNumber, // 핸드폰 번호
                      knb: 'NB', // 업무구분코드
                      rrn: this.$bizUtil.isEmpty(custInfo.knbBkno) ? '' : (custInfo.knbFrno + custInfo.knbBkno),
                      moblAthntCd: this.moblAthntCd, // 모바일인증코드
                      moblAthntPswd: this.moblAthntPswd, // 모바일인증비밀번호
                      reqUnqNo: this.reqUnqNo, // 요청고유번호
                      respUnqNo: this.respUnqNo, // 응답고유번호
                      athntNo: this.authNumber, // 인증번호
                      aprvNoErrCnt: this.aprvNoErrCnt // 인증번호 오류 횟수
                    }

      let lv_Vm = this

      this.post(lv_Vm, pParams, 'txTSSFN70S2', 'S')
        .then(function (response) {

          if (response.body !== null && response.body !== '' && response.body.rsltCd === '0000') {

            let lv_TmpResult = response.body

            if (lv_TmpResult) {
              lv_Vm.isDisabled = true //본인확인 서비스 이용동의 체크박스 비활성화
              lv_Vm.isTelecomTypeDisabled = true //통신사 비활성화

              // timer init
              window.clearInterval(lv_Vm.interval) // 타이머 종료
              
              //req auth btn init
              lv_Vm.reqAuthBtnTitle = '인증번호 요청'
              lv_Vm.isReqAuthNumberBtnDisabled = true // 인증번호 요청 버튼 비활성화
              
              //auth number input
              //lv_Vm.authNumber = '' // 인증번호 초기화
              lv_Vm.isAuthNumberDisabled = true // 인증번호 input 비활성화

              //auth confirm btn init
              lv_Vm.isAuthConfirmBtnDisabled = true // 인증확인 버튼 비활성화
              lv_Vm.authMessage = 'success'

              // 오늘 날짜 조회
              lv_Vm.$commonUtil.getServerTime().then(function (response) {
                lv_Vm.authSuccessTime = moment(response.toString()).format('YYYY-MM-DD HH:mm')
                lv_Vm.fn_FinalCheck()
              })
            }
            
          } else { // 정상처리가 아닌경우
            lv_Vm.getStore('confirm').dispatch('ADD', response.msgComm.msgDesc)

            if (response.msgComm.msgCd === 'ECMU001') { // 인증번호 3회 오류 시
              lv_Vm.authMessage = 'error'

              // init timer
              lv_Vm.countDown = COUNTER
              lv_Vm.$refs.vWatcher.clearValidation()
              lv_Vm.authNumber = '' // 인증번호 값 초기화
              lv_Vm.isAuthNumberDisabled = true // 인증번호 입력필드 비활성화
              lv_Vm.isAuthConfirmBtnDisabled = true // 인증확인 버튼 비활성화
              window.clearInterval(lv_Vm.interval) // 타이머 종료

            }
          }
        })
        .catch(function (error) {
          window.vue.error(error)
        })
    },

    /******************************************************************************
     * Function명 : fn_StartCounter
     * 설명       : 인증 타이머 카운트다운
     ******************************************************************************/
    fn_StartCounter(){
      this.countDown = COUNTER

      let min = Number(COUNTER.split(':')[0])
      let sec = Number(COUNTER.split(':')[1])

      let lv_Vm = this

      // 인증 count down 시작
      this.interval = window.setInterval(function(count){
        count.setTime(count-1000) //interval clear 시 count 초기화
        
        let m = count.getMinutes() //type int
        let s = count.getSeconds() //type int

        if( m+s > 0  ){
          let min = String(m).padLeft(2, '0') //type string
          let sec = String(s).padLeft(2, '0') //type string

          lv_Vm.countDown =  min.concat(':', sec) // 07:00

        }
        // 시간 초과
        else {

          // init
          lv_Vm.$refs.vWatcher.clearValidation()
          lv_Vm.countDown = COUNTER
          lv_Vm.authNumber = '' // 인증번호 값 초기화
          lv_Vm.isAuthNumberDisabled = true // 인증번호 입력필드 비활성화
          lv_Vm.isAuthConfirmBtnDisabled = true // 인증확인 버튼 비활성화

          window.clearInterval(lv_Vm.interval) // 타이머 종료

        }
      }, 1000, new Date(0, 0, 0, 0, min, sec))

    },

    /******************************************************************************
     * Function명 : fn_ConfirmOpen
     * 설명       : confirm창 오픈 함수
     *              confirm 창이 열릴경우 vuex에 열림 표시를 저장한다.
     ******************************************************************************/
    fn_ConfirmOpen() {
      let contentMsg = '본인확인 서비스 이용 동의시 인증번호가 전송됩니다.\n이용에 동의하시겠습니까?'
      
      let lv_AlertPop = this.$bottomModal.open(MSPBottomAlert, {
          properties: {
            //single: true,
            title: "본인확인서비스이용동의",
            content: contentMsg,
            title_pos_btn: "예",
            title_neg_btn: "아니오"
          },
          listeners: {
            onPopupConfirm: () => {
              this.$bottomModal.close(lv_AlertPop)
              this.isCheckAgree = true
              this.fn_ReqAuthNumber()
            },
            onPopupClose: () => {
              this.$bottomModal.close(lv_AlertPop)
            }
          }
        })
    },

    /******************************************************************************
     * Function명 : fn_FinalCheck
     * 설명       : 본인인증과 븐인인증 동의 최종 체크
     ******************************************************************************/
    fn_FinalCheck() {
      this.isNextBtnDisabled = !(this.authMessage === 'success' && this.agreeCb1 && this.agreeCb2 && this.agreeCb3)
      this.agreeCbAll = (this.agreeCb1 && this.agreeCb2 && this.agreeCb3)
    },

    /******************************************************************************
     * Function명 : fn_AllAgree
     * 설명       : 전체동의
     ******************************************************************************/
    fn_AllAgree() {
      this.agreeCb1 = this.agreeCbAll
      this.agreeCb2 = this.agreeCbAll
      this.agreeCb3 = this.agreeCbAll
    },

    //개발 편의 not empty
    isNotEmpty(value) {
      return !this.$bizUtil.isEmpty(value)
    },

    /******************************************************************************
    * Function명 : fn_AgreeCnfrm
    * 설명       : 본인확인서비스 이용동의 안내 
    *           - OPEN(컨펌팝업열기), Cancel(창닫기), Confirm(창닫기) 선택에 대한 화면 이동
    ******************************************************************************/
    fn_AgreeCnfrm(param) {
      switch (param) {
        case 'Open':
          if(this.isCheckAgree){
            this.isCheckAgree = false
            this.$refs.agreeCnfrmSheet.open()
          }
          break
        case 'CloseBtn':
          this.$refs.agreeCnfrmSheet.close()
          break
        case 'Confirm': // 약관 확인
          this.$refs.agreeCnfrmSheet.close('S')
          this.fn_AgreeConfirmYes() // 동의 나이스 링크 호출
          break
        case 'AllConfirm': // 전체 약관 확인
          this.$refs.agreeCnfrmSheet.close('S')
          break
      }
    },
    
    /******************************************************************************
    * Function명 : fn_AgreeConfirmYes
    * 설명       : 본인확인 서비스 이용 안내 confirm팝업창의 '동의' / '동의안함' 버튼 선택 시
    ******************************************************************************/
    fn_AgreeConfirmYes () {
      this.isCheckAgree = true
      this.fn_AgreePopContent()
    },
    
    /******************************************************************************
    * Function명 : fn_AgreePopContent
    * 설명       : 이통사 본인확인 서비스 이용에 따른 동의 나이스 링크 호출
    ******************************************************************************/
    fn_AgreePopContent () {
      if (this.$commonUtil.isMobile()) { // 모바일에서 작동
        window.fdpbridge.exec('intentBrowserPlugin', {uri: this.sCertVnoUrl}, () => {}, () => {})
      } else { // PC에서 작동
        window.open(this.sCertVnoUrl, '_blank')
      }
    },
    
    /******************************************************************************
    * Function명 : fn_SheetClose
    * 설명       : 본인확인서비스 이용동의 안내 Close Fn
    *              - 약관동의로 Close / 강제로 Close 구분 목적
    * @param  fn: BottomSheet Close Function
    * @param  item: BottomSheet Event Name
    * @notice 강제로 / X버튼으로 닫을시 Event Name = 'swipe'
    *         - 약관 동의하게되면 item = 'S' 
    ******************************************************************************/
    fn_SheetClose(fn_CloseSheet, item) {
      this.isCheckAgree = item === 'swipe' ? false : true
      fn_CloseSheet()
    }
  } // Method end
};
</script>