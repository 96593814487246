/*
 * 업무구분: 보험증권재발행
 * 화 면 명: MSPNB006D
 * 화면설명: STEP4. 보유계약조회
 * 접근권한: 모든사용자
 * 작 성 일: 2023.01.13
 * 작 성 자: 김진원
 */
<template>
  <ur-page-container class="msp" :show-title="false" title="증권재발행" type="subpage" action-type="none" :topButton="true" @on-scroll-bottom="fn_GetService()">
    <ur-box-container alignV="start"  direction="column" class="msp-pm-wrap">
      <div class="ns-sliding-topbar">
        <mo-collapsing-header-view headerColor="#fff" :fixedDiff="34" ref="headerview">
          <!-- 헤더 타이틀 영역 -->
          <template #fixed="{scrollRate}">
            <mo-top-bar color="primary" scroll-shadow >
              <div slot="nav" class="icon-wrapper" @click="fn_pre">
                <mo-icon color="white" icon-size="36px" borderless>drawer</mo-icon>
              </div>
              <div class="ui-gnb-title__main">{{scrollRate > 0.5 ? '증권재발행' : '증권재발행'}}</div>
            </mo-top-bar>

            <!-- 진행단계 -->
            <ur-box-container direction="row" class="msp-step-type2">
              <div class="step-con">
                  <strong class="title">보유계약조회</strong>
                  <span class="step-num">
                    <b class="num ing">4</b>
                    <span class="num">4</span>
                  </span> 
              </div>
            </ur-box-container>
            <!-- 진행단계 //-->
          </template>

          <!-- collapsing 영역 -->
          <template #collapsing>
            <!-- 계약자 정보 -->
            <ur-box-container alignV="start" componentid="ur_box_container_001" direction="column" class="ns-list-area msp-list-area bd-b-Ty1 pb0">  
              <ur-box-container alignV="start" componentid="ur_box_container_002" direction="column" class="ns-check-list">
              <div class="list-item w100 pb0">
                <div class="list-item__contents pb20">
                  <div class="list-item__contents__title fexTy3"> <!--2023.01.31 수정 : 'fexTy3' 추가  //-->
                    <span class="name txtSkip maxW250 fexInt pr10">{{$props.custInsrdInfoVO.custNm}}</span>
                    <mo-badge class="badge-sample-badge lightgreen sm" text="계약자" shape="status">계약자</mo-badge> 
                  </div>
                  <div class="list-item__contents__info">
                    <div class="txt_box">
                      <span class="crTy-bk1">{{age}}세</span><em>|</em><span class="crTy-bk1">{{rrn}}</span>
                    </div>
                  </div>
                </div>
              </div> 
              </ur-box-container>
            </ur-box-container>
            <!-- 계약자 정보 //-->
            <!-- 재발행 사유 -->   
            <ur-box-container alignV="start" componentid="ur_box_container_006" direction="row" class="rePublish-box">
              <span class="pal1524 pb10 fs16rem crTy-bk7">
                재발행사유 
                <mo-button color="normal" shape="border" size="small" @click="fn_ReasonClick('12')" :class="reason==='12'?'blue ml10':'black ml10'">분실</mo-button>
                <!--mo-button color="normal" shape="border" size="small" @click="fn_ReasonClick('23')" :class="reason==='12'?'black ml10':'blue ml10'">보장확인</mo-button -->
              </span>
              <ur-box-container alignV="start" componentid="ur_box_container_006" direction="row" class="text-gray-box ty1">
                <span class="fs14rem crTy-bk7 ic">컨설턴트 본인 수금건 한해 조회됩니다.</span>
              </ur-box-container>
            </ur-box-container>
            <!-- 재발행 사유 //-->
          </template>

          <!-- stickey 영역 -->
          <template #sticky>
            <!-- 총건수  -->
            <ur-box-container alignV="start" componentid="" direction="column" class="ns-check-sum">
              <mo-list-item>
                <mo-checkbox v-model="isCheckAll" @input="fn_CheckAll" size="small" />
                <div v-if="checkCount>0" class="list-item__contents">
                  <div class="list-item__contents__title">
                    <span class="sum"><strong class="crTy-blue3">{{checkCount}}</strong> 건 선택</span>
                  </div>
                </div>
                <div v-else class="list-item__contents">
                  <div class="list-item__contents__title">
                    <span class="sum">총 <strong class="crTy-blue3">{{totalCount}}</strong> 건</span>
                  </div>
                </div>
              </mo-list-item>
            </ur-box-container>
            <!-- 총건수  //-->
          </template>

          <!-- scroll 영역 -->
          <template #scroll>
            <!-- 보유계약조회 -->
            <ur-box-container alignV="start" componentid="ur_box_container_001" direction="column" class="ns-list-area msp-list-area">
              <!-- list -->
              <template v-if="nBSecusRpblAplListSVO.length > 0">
                <ur-box-container alignV="start" componentid="ur_box_container_002" direction="column" class="ns-check-list type-2">
                  <mo-list :list-data="nBSecusRpblAplListSVO">
                    <template #list-item="{item, index}">
                      <!-- list row -->
                      <mo-list-item :class="{'on' : item.checked}"> <!-- item이 체크되어 선택되었음을 표시  --->
                        <mo-checkbox v-model="item.checked" @input="fn_ItemCheck" :disabled="item.uiMesg != '-' && item.uiMesg != '신청가능'"/>

                        <div class="list-item__contents">
                          <div class="list-item__contents__title"> 
                            <span class="txtSkip">{{item.prdtNm}}</span> <!-- 상품명 -->
                          </div>
                          <div class="list-item__contents__info">
                            <span class="fs16rem fwb maxW80 txtSkip">{{item.insrdNmMask}}</span><em>|</em> <!-- 피보험자 -->
                            <span class="crTy-bk7 fs14rem mr10">계약일 </span><span>{{item.contYmd}}</span> <!-- 계약일 -->
                          </div>
                          <div class="list-item__contents__info">
                            <span class="crTy-bk7 fs14rem mr10">계약번호 </span><span>{{item.contNoMask}}</span><em>|</em> <!-- 계약번호 -->
                            <span class="crTy-bk7 fs14rem mr10">상태 </span><span>{{item.contStatNm}}</span><em>|</em> <!-- 계약상태 -->
                            <span class="crTy-bk7 fs14rem mr10">보험료</span><span>{{item.smtotPrm}}</span> <!-- 보험료 -->
                          </div>
                          <div class="list-item__contents__info fexTy5 pt7"><!-- :disabled="item.uiMesg != '-' && item.uiMesg != '신청가능'" -->
                            <msp-bottom-select ref="bottomSelect" :items="receiveItems" v-model="item.uiSecusCvancMthCd" @input="fn_changeUiSecusCvancMthCd(item)" class="ns-dropdown-sheet mr10 display-inline-flex select-type1" placeholder="받는방법" bottom-title="받는방법" closeBtn scrolling/>
                            <mo-badge class="badge-sample-badge lightblue sm"  text="신청가능" shape="status" v-if="item.uiMesg === '신청가능'">{{item.uiMesg}}</mo-badge> 
                            <span class="ns-tool-tip display-inline-flex align-item-center" v-else-if="item.uiMesg != '-'">
                              <mo-badge class="badge-sample-badge lightred sm" text="신청불가능" shape="status">{{item.uiMesg}}</mo-badge> 
                              <mo-icon :id="`location${index}`" icon="msp-tool-tip" class="ml5">tool-tip</mo-icon>
                              <mo-tooltip :target-id="`location${index}`" class="tool-tip-box w50vw">{{item.trtRsltCdNm}}</mo-tooltip>
                            </span>
                          </div>
                        </div>
                      </mo-list-item>
                      <!-- list row end -->
                    </template>
                  </mo-list>   
                </ur-box-container>
              </template>
              <!-- list end -->
              <!-- list count 0 -->
              <template v-else>
                <ur-box-container alignV="start" componentid="" direction="column" class="no-data">
                  <mo-list-item ref="expItem">
                    <div class="list-item__contents">
                      <div class="list-item__contents__info vh50">
                        <span class="ns-ftcr-gray"><p class="mt20">데이터가 존재하지 않습니다.</p></span>
                      </div>
                    </div>
                  </mo-list-item>
                </ur-box-container>
              </template>
              <!-- list count 0 end -->
            </ur-box-container>
            <!-- 보유계약조회 //-->

            <!-- 하단 버튼영역 -->
            <ur-box-container alignV="start" componentid="ur_box_container_002" direction="column" class="ns-btn-relative">
              <div class="relative-div">
                <mo-button componentid="mo_button_002" color="primary" shape="border" size="medium" class="ns-btn-round white" @click="fn_pre">이전</mo-button>
                <mo-button componentid="mo_button_002" color="normal" shape="border" size="medium" class="ns-btn-round blue" @click="fn_next" :disabled="checkCount===0">전자서명</mo-button>
              </div>
            </ur-box-container>
            <!-- 하단 버튼영역 //-->  
            <!-- 하단 버튼영역 -->
            <!-- <ur-box-container alignV="start" componentid="ur_box_container_002" direction="column" class="ns-btn-relative">
              <div class="relative-div">
                <mo-button componentid="mo_button_002" color="normal" shape="border" size="medium" class="ns-btn-round blue">전자서명진행</mo-button>
              </div>
            </ur-box-container> -->
            <!-- 하단 버튼영역 //-->  
          

          </template>
          <!-- scroll 영역 //-->

        </mo-collapsing-header-view>
       </div>
    </ur-box-container>
  </ur-page-container>
</template>
<script>
const TEST = true
/***********************************************************************************
 * 공통 라이브러리 INCLUDE 영역	                                                   *
 ***********************************************************************************/
import MSPBottomAlert from '@/ui/_cmm/components/MSPBottomAlert'
import TrnsJsonCretUtil from '@/ui/bu/TrnsJsonCretUtil'
import moment from 'moment'

export default {
  /***********************************************************************************
   * Vue 파일 속성 정의 영역	                                                       *
   ***********************************************************************************/
  name: "MSPNB006D",
  screenId: "MSPNB006D",
  components: {},

  props:{
    custInsrdInfoVO: {type: Object},
    authTime: {type: String}
  },
  /***********************************************************************************
   * Life Cycle 함수 정의 영역	                                                     *
   ***********************************************************************************/
  created() {
    this.fn_SetCustInfo() //고객정보
    this.fn_GetService() //신청보유계약조회

    let lv_Vm = this
    this.$commonUtil.getServerTime().then(function (response) {
      lv_Vm.today = moment(response).format('YYYYMMDD')
      //lv_Vm.todayDash = moment(response).format('YYYY-MM-DD')
    })

  },


  /***********************************************************************************
   * 화면변수 선언 영역	                                                             *
  ***********************************************************************************/
  data() {
    return {
      age: '', // step1에서 조회된 고객의 연령
      rrn: '', // step1에서 조회된 고객의 주민번호
      isCheckAll: false,
      nBSecusRpblAplListSVO: [], //보유계약리스트
      stndKeyList: [{stndKeyId: "next_key", stndKeyVal: "1"}], //리스트 더보기 기능
      reason: '12', // 12: 분실, 23: 보장확인 -- 2023.04.18 김재원 차장님 요청 (증권재발행에서는 보장확인은 없다. 분실로 고정 셋팅)
      today: '',
      //todayDash: '',
    }
  },

  /***********************************************************************************
   * Computed 함수 정의 영역                                                         *
   ***********************************************************************************/
  computed: {
    receiveItems() {
      let items = [
                    {value: '0', text: '선택'},
                    {value: '06', text: '모바일'},
                    {value: '01', text: '이메일'},
                    {value: '02', text: 'FC전달 지점발행'},
                    {value: '04', text: '자택 일반우편'},
                    {value: '05', text: '직장 일반우편'}
                  ]
      return items
    },
    totalCount() {
      return this.nBSecusRpblAplListSVO.length
    },
    isNoSelectedReceive() {
      return this.nBSecusRpblAplListSVO.filter(el => el.checked && el.uiSecusCvancMthCd === '0').length
    },
    checkCount() {
      return this.nBSecusRpblAplListSVO.filter(el => el.checked).length
    },
  },
  mounted () {
    // 스크롤 애니메이션 instance 선언
    //this.lv_HeaderviewScrollCompID = this.$refs.headerview.getScrollElementId()
    //document.getElementById(this.lv_HeaderviewScrollCompID).addEventListener('scroll', this.$refs.page.onScrollEvent)
  },

  /***********************************************************************************
   * 사용자 함수 정의 영역                                                           *
   ***********************************************************************************/
  methods: {
    fn_ReasonClick(code){
      this.reason = code
    },

    /******************************************************************************
    * Function명 : fn_SetCustInfo
    * 설명       : 고객 정보 셋팅
    ******************************************************************************/
    fn_SetCustInfo() {
      const custInfo = this.$props.custInsrdInfoVO

      // 주민번호 마스킹
      if( this.isNotEmpty(custInfo.rrn) ){
        this.rrn = custInfo.rrn.replace(/(^\d{6})(\d{1}).*/, '$1-$2******') //ex : 2301013******
      }

      this.age = this.$pmUtil.fn_GetAge(this.$bizUtil, custInfo.knbFrno) // 연령 셋팅.

      console.log('cust info : ', custInfo)

    },

    fn_CheckAll(data) {
      this.nBSecusRpblAplListSVO.forEach(element => {
        if( element.uiMesg == '-' || element.uiMesg == '신청가능' ){
          element.checked = data
        }
      })

      this.fn_ItemCheck()
    },

    fn_ItemCheck() {
      let unCheckCount = this.nBSecusRpblAplListSVO.filter(element => (element.uiMesg == '-' || element.uiMesg == '신청가능') && !element.checked).length
      this.isCheckAll = unCheckCount === 0
    },

    /******************************************************************************
    * Function명 : fn_GetService
    * 설명       : 신청보유계약조회 호출
    ******************************************************************************/
    fn_GetService() {
      
      // 다음 페이지 번호 (마지막 페이지는 stndKeyVal 의 값이 없다.) [{stndKeyId: 'next_key', stndKeyVal: '1'}]
      if( this.$bizUtil.isEmpty(this.stndKeyList[0].stndKeyVal) ){
        return
      }

      const custInfo = this.$props.custInsrdInfoVO

      let params = {
        //custNm: custInfo.custNm, // 고객이름
        //knbFrno: custInfo.knbFrno, // 주민번호 앞번호
        //knbBknoCipher: custInfo.knbBknoCipher, // 암호화된 주민번호 뒷번호
        //knbBkno: custInfo.knbBkno, // 주민번호 뒷번호 평문
        bpid: custInfo.custId // 고객BPID
      }

      let lv_Vm = this
      this.post(lv_Vm, params, 'txTSSNB08S1', 'S')
        .then(function (response) {
          // 서버 데이터 가져오기
          if (response.body !== null && response.msgComm !== null && response.msgComm.msgCd === 'EFWS003') {

            let responseList = response.body.nBSecusRpblAplListSVO

            console.log('contract list : ', responseList)
            
            if (lv_Vm.isNotEmpty(responseList) && responseList.length > 0) {
              responseList.forEach(el => el.checked = false) // 결과값에는 checked 가 없다. 이 상태로 mo-list 에 바인딩 되면, mo-checkbox 의 작동에 버그가 생긴다. 하~~ 하루 종일 삽질을 ㅡ,.ㅡ
              lv_Vm.nBSecusRpblAplListSVO = lv_Vm.nBSecusRpblAplListSVO.concat(responseList)
              lv_Vm.stndKeyList = response.trnstComm.stndKeyList // 더보기 키값
            }

            // 202007 외화보험 적용
            for (let i = 0; i < lv_Vm.nBSecusRpblAplListSVO.length; i++) {
              lv_Vm.nBSecusRpblAplListSVO[i].smtotPrm = lv_Vm.$bizUtil.replaceAll(lv_Vm.nBSecusRpblAplListSVO[i].smtotPrm, ',', '')
              lv_Vm.nBSecusRpblAplListSVO[i].smtotPrm = lv_Vm.$bizUtil.numberWithCommasCurr(lv_Vm.nBSecusRpblAplListSVO[i].smtotPrm, lv_Vm.nBSecusRpblAplListSVO[i].crncyCd, 'Y', 'Y', 4)
            }

          } else {
            lv_Vm.getStore('confirm').dispatch('ADD', response.msgComm.msgDesc)

          }
        }).catch(function (error) {
          window.vue.error(error)
        })
    },



    /******************************************************************************
    * Function명 : fn_RecvMthCheck
    * 설명       : 받는방법 체크 하여, 선택 할 수 없는 항목은 초기화 한다.
    ******************************************************************************/
    fn_RecvMthCheck(msgCode, item){
      item.checked = false
      item.uiSecusCvancMthCd = '0'
      item.uiMesg = '-'

      let message = this.$t('nb')[msgCode] + '\n' +
                    this.$t('nb')['ENBC026'] // 고객정보 수정 화면으로 이동 하시겠습니까?
      
      this.fn_ConfirmOpen(message)
    },




    /*********************************************************
     * Function명: fn_changeUiSecusCvancMthCd
     * 설명: 받는방법(콤보:fdp-select)를 변경선택 시, 변경하는 이벤트 핸들러
     * Params: _selected {Object}: 선택 이벤트 전달 객체 <필수>
     *         _item {Object}: 받는방법 목록 중 현재 선택된 아이템
     *
     * Return: N/A
     *********************************************************/
    fn_changeUiSecusCvancMthCd(item) {
      const custInfo = this.$props.custInsrdInfoVO
      const code = item.uiSecusCvancMthCd

      // 선택인 경우
      if( code === '0' ){
        item.checked = false
        item.uiMesg = '-'
        return

      } else if( code === '01' ){ // 이메일
        if (this.$bizUtil.isEmpty(custInfo.emailAddr) || custInfo.emailReciYn !== '1') {
          this.fn_RecvMthCheck('ENBC022', item) // '이메일이 등록되지 않았거나 수신거부 입니다'
          return
        }

      } else if( code === '04' ){ // 자택 일반우편
        if (this.$bizUtil.isEmpty(custInfo.homAddr) || (custInfo.mailRcppliScCd !== '0002')) {
          this.fn_RecvMthCheck('ENBC024', item) // '우편물수령지가 자택이 아니거나 자택주소 정보가 등록되어 있지 않습니다'
          return
        }

      } else if( code === '05' ){ // 직장 일반우편
        if (this.$bizUtil.isEmpty(custInfo.jobpAddr) || (custInfo.mailRcppliScCd !== '0003')) {
          this.fn_RecvMthCheck('ENBC025', item) // '우편물수령지가 직장이 아니거나 직장주소 정보가 등록되어 있지 않습니다'
          return
        }

      } else if( code === '06' ){ // 모바일
        if (this.$bizUtil.isEmpty(custInfo.celno) || custInfo.smsReciCnsntYn !== '1') {
          this.fn_RecvMthCheck('ENBC023', item) // '모바일이 등록되지 않았거나 수신거부 입니다'
          return
        }

      }

      let text = this.receiveItems.find(element => element.value == code).text
      item.uiSecusCvancMthCdNm = text
      item.ck = true

      // inDTO 셋팅
      let inDto = this.$bizUtil.cloneDeep(item)
      inDto.rpblRsnCd = this.reason // 재발행사유코드

      if (item.planId !== item.contNo) inDto.reprContNo = ''

      // 증권전달방법코드
      if (code === '01') { // 이메일
        inDto.secusCvancMthCd = '4' // 증권전달방법코드(이메일)
        inDto.pblPlcScCd = '30' // 발행장소구분코드(이메일)

      } else if (code === '02') { // 방문 - 현지발행
        inDto.secusCvancMthCd = '1' // 증권전달방법코드(방문)
        inDto.pblPlcScCd = '20' // 발행장소구분코드(현지)

      } else if (code === '04') { // 자택일반우편
        inDto.secusCvancMthCd = '2' // 증권전달방법코드(자택일반우편)
        inDto.pblPlcScCd = '10' // 발행장소구분코드(본사)

      } else if (code === '05') { // 직장일반우편
        inDto.secusCvancMthCd = '3' // 증권전달방법코드(직장일반우편)
        inDto.pblPlcScCd = '10' // 발행장소구분코드(본사)

      } else if (code === '06') { // 모바일
        inDto.secusCvancMthCd = 'M' // 증권전달방법코드(모바일)
        inDto.pblPlcScCd = '40' // 발행장소구분코드(모바일)

      }

      let params = {
        nBSecusRpblAplListSVO: [inDto]
      }

      //console.log('params : ', params)

      let lv_Vm = this
      this.post(this, params, 'txTSSNB08S2', 'S')
        .then(function (response) {

          // 서버 데이터 가져오기
          if (response.body !== null && response.msgComm !== null && response.msgComm.msgCd === 'EFWS003') {
            let responseList = response.body.nBSecusRpblAplListSVO

            if( lv_Vm.isNotEmpty(responseList) && responseList.length > 0 ){
              console.log('responseList : ', responseList)

              if (item.contNo === responseList[0].contNo) {
                if( responseList[0].uiMesg === '신청가능' ){
                  item.checked = true
                } else {
                  item.checked = false
                }

                lv_Vm.fn_ItemCheck()

                item.uiMesg = responseList[0].uiMesg
                item.trtRsltCdNm = responseList[0].trtRsltCdNm // 처리결과코드명(신청결과)
                item.secusShpCd = responseList[0].secusShpCd // 증권형태코드
                item.aplFofOrgNo = responseList[0].aplFofOrgNo // 신청지점조직번호
                item.emailAddr = responseList[0].emailAddr // 이메일주소
                item.faxTelno = responseList[0].faxTelno // 팩스전화번호
                item.pblPlcScCd = responseList[0].pblPlcScCd // 발행장소구분코드
                item.secusCvancMthCd = responseList[0].secusCvancMthCd // 증권전달방법코드
                item.pblPlcScCd = responseList[0].pblPlcScCd // 발행장소구분코드
                item.prcsfCd = responseList[0].prcsfCd // 프로세스 프래그코드
                item.sno = responseList[0].sno // 일련번호
                item.systmKey = responseList[0].systmKey // 시스템키
                item.aplDofOrgNo = responseList[0].aplDofOrgNo // 신청지역단조직번호
                item.contAmt = responseList[0].contAmt // 계약금액
                item.aplYmd = responseList[0].aplYmd // 신청일자
                item.pblYmd = responseList[0].pblYmd // 발행일자
                item.secusPblScCd = responseList[0].secusPblScCd // 증권발행구분코드 01:개인, 02:통합
                item.planId = responseList[0].planId // 설계ID
                item.contNo = responseList[0].contNo // 계약번호
                item.pblNo = responseList[0].pblNo // 발행번호
                item.duseYmd = responseList[0].duseYmd // 폐기일자
                item.aplrEno = responseList[0].aplrEno // 신청자사번
                item.rrnFrnoNo = responseList[0].rrnFrnoNo // 주민등록번호앞자리번호
                item.rrnBknoNo = responseList[0].rrnBknoNo // 주민등록번호뒷자리번호
                item.expYmd = responseList[0].expYmd // 만기일자
                item.ltpadYm = responseList[0].ltpadYm // 종납년월
                item.ltpadNts = responseList[0].ltpadNts // 종납횟수
                item.ltpadYmd = responseList.ltpadYmd // 종납일자
                item.padMms = responseList[0].padMms // 납입개월수
                item.padCylCd = responseList[0].padCylCd // 납입주기코드
                item.padCylNm = responseList[0].padCylNm // 납입주기명
                item.contStatCd = responseList[0].contStatCd // 계약상태코드
                item.clmnyFofOrgNo = responseList[0].clmnyFofOrgNo // 수금지점조직번호
                item.clmnyFofNm = responseList[0].clmnyFofNm // 수금지점명
                item.aplnPadScCd = responseList[0].aplnPadScCd // 자동대출납입구분코드
                item.aplnPadScCdNm = responseList[0].aplnPadScCdNm // 자동대출납입구분코드명
                item.pllnYn = responseList[0].pllnYn // 보험계약대출여부
                item.padStatCd = responseList[0].padStatCd // 납입상태코드
                item.padStatCdNm = responseList[0].padStatCdNm // 납입상태코드명
                item.contScCd = responseList[0].contScCd // 계약구분코드
                item.busnCnctRfltYn = responseList[0].busnCnctRfltYn // 업무연락반영여부
                item.contAmt = responseList[0].contAmt // 계약금액
                item.prcd = responseList[0].prcd // 상품코드
                item.prdtVcd = responseList[0].prdtVcd // 상품버전코드
                item.reprContNo = responseList[0].reprContNo // 대표계약번호
                item.rpblRsnCd = responseList[0].rpblRsnCd // 재발행사유코드
                //lv_Vm.fn_rowClick(_item, responseList[0].uiMesg)
              }
            }
          } else {
            lv_Vm.getStore('confirm').dispatch('ADD', response.msgComm.msgDesc)

          }
        }).catch(function (error) {
          window.vue.error(error)
        })
    },


    /******************************************************************************
    * Function명 : fn_pre
    * 설명       : 이전 버튼 클릭
    ******************************************************************************/
    fn_pre() {
      this.$emit('pre')
    },


    /******************************************************************************
    * Function명 : fn_next
    * 설명       : 다음 버튼 클릭
    ******************************************************************************/
    fn_next() {
      // 받는방법을 선택 하지 않은 건 체크.
      if( this.isNoSelectedReceive > 0 ){
        this.getStore('confirm').dispatch('ADD', this.$t('nb')['ENBC021']) // 받는 방법을 선택하지 않은 계약이 있습니다.
        return
      }

      this.fn_EltrnSignDocDataSave() //모청 서명 호출 전 문서에 매핑 할 데이터 저장

      // this.fn_dirCretTrt(this.fNInsrFnEltrnSignDoctVO) // NAS 디렉토리 생성
      // this.fn_getEncrKeyForm() // 암호키 가져오기
      // this.fn_PdfViewerPlugin() // 전자서명앱 호출
      // this.fn_PdfSignerDataSave() // 전자서명 후처리
      // this.fn_UploadPlugin() // PDF 업로드 BRIDGE - externalUploadPlugin
      // this.fn_SerivceEltrnSignCmplt() // 전자서명 완료처리
      // this.fn_GetP1Service() // 재발행신청
    },


    /******************************************************************************
      * Function명 : fn_GetPdfData
      * 설명       : PDF 서명 문서 생성 및 호출
    ******************************************************************************/
    /*fn_GetPdfData() {
      const custInfo = this.$props.custInsrdInfoVO
      let contTelFieldData = ''
      let contAddrFieldData = ''

      if (custInfo.mailRcppliScCd === '0002') { // 우편물 수령처 자택
        contTelFieldData = custInfo.homTelnoDash === null ? '' : custInfo.homTelnoDash

        if (custInfo.homeAddrScCd === '2') {
          contAddrFieldData = custInfo.homAddr + ' ' + custInfo.homebuilding + ' ' + custInfo.homDtlad + ' ' + custInfo.homestrSuppl1

        } else if (custInfo.homeAddrScCd === '1') { // 자택 구 주소인 경우
          contAddrFieldData = custInfo.homAddr + ' ' + custInfo.homDtlad
        }
      } else if (custInfo.mailRcppliScCd === '0003') { // 우편물 수령철 직장
        contTelFieldData = custInfo.jobpTelnoDash === null ? '' : custInfo.jobpTelnoDash

        if (custInfo.jobAddrScCd === '2') { // 직장이 신주소인 경우
          contAddrFieldData = custInfo.jobpAddr + ' ' + custInfo.jobpbuilding + ' ' + custInfo.jobpDtlad + ' ' + custInfo.jobpstrSuppl1

        } else if (custInfo.jobAddrScCd === '1') { // 직장 구 주소인 경우
          contAddrFieldData = custInfo.jobpAddr + ' ' + custInfo.jobpDtlad

        }
      }

      let birthDay = moment(this.$bizUtil.addBirthFrontYear(custInfo.knbFrno)).format('YYYY-MM-DD') || ''
      let userNm = this.getStore('userInfo').getters.getUserInfo.userNm
      let checkList = this.nBSecusRpblAplListSVO.filter(item => item.checked)
      let contNoArray = []
      let prdtNmArray = []
      let insrdNmArray = []
      let contYmdArray = []
      let smtotPrmArray = []
      let uiSecusCvancMthCdNmArray = []
      let celNoSelfAthntDtm = this.$props.authTime.substr(0, 4) + '년 ' +
                              this.$props.authTime.substr(5, 2) + '월 ' +
                              this.$props.authTime.substr(8, 2) + '일 ' +
                              this.$props.authTime.substr(11, 5) // 휴대폰본인인증일시

      let fcAplYmd = this.today.substr(0, 4) + '년 ' + this.today.substr(4, 2) + '월 ' + this.today.substr(6, 2) + '일'

      for( let idx in checkList ){
        contNoArray.push(checkList[idx].contNo)
        prdtNmArray.push(checkList[idx].prdtNm)
        insrdNmArray.push(checkList[idx].insrdNm)
        contYmdArray.push(checkList[idx].contYmd)
        smtotPrmArray.push(checkList[idx].smtotPrm)
        uiSecusCvancMthCdNmArray.push(checkList[idx].uiSecusCvancMthCdNm)
      }

      let fNInsrFnEltrnSignDoctVO = {
        busnScCd: 'NB',
        custId: custInfo.custId, // 고객ID
        custNm: custInfo.custNm, // 고객명
        custRrn: String(custInfo.rrn).substring(0, 6) + '-' + String(custInfo.rrn).substring(6, 13), // 주민번호 확인요
        emailAddr: custInfo.emailAddr, // 이메일
        
        fNInsrFnEltrnSignDoctListVO: [
          {
            docNm: 'nbCrdtInfoWcnst',
            imgSclsfCd: '', // 소분류코드
            prtPageFgr: '1',
            cvancYn: 'N',
            fNEltrnDocDataFldVO: [
              {fieldNm: 'CRDT_ACCT_1',fieldData: 'V'}, // 개인정보 동의수집 동의함
              {fieldNm: 'CRDT_ACCT_2',fieldData: 'V'}, // 고유식별정보 처리사항동의함
              {fieldNm: 'CRDT_ACCT_3',fieldData: 'V'}, // 고유식별정보 처리사항동의함
              {fieldNm: 'CRDT_REQ_YEAR',fieldData: this.today.substr(2, 2)}, // 신청일 년도끝자리 2자리
              {fieldNm: 'CRDT_REQ_MONTH',fieldData: this.today.substr(4, 2)}, // 신청일 월 2자리
              {fieldNm: 'CRDT_REQ_DAY',fieldData: this.today.substr(6, 2)}, // 신청일 월 2자리
              {fieldNm: 'CUST_NM',fieldData: custInfo.custNm || ''}, // 고객명
            ]
          },
          {
            docNm: 'nbInsrSecusLor',
            imgSclsfCd: '', // 소분류코드
            prtPageFgr: '1',
            cvancYn: 'N',
            fNEltrnDocDataFldVO: [
              // 계약자 인적사항
              {fieldNm: 'CONT_NM', fieldData: custInfo.custNm || ''}, // 계약자명
              {fieldNm: 'CONT_DOB', fieldData: birthDay}, // 나이 AS-IS: 생년월일
              {fieldNm: 'CONT_ADDR', fieldData: contAddrFieldData}, // 주소 (우편물 수령처 주소)
              {fieldNm: 'CONT_EMAIL', fieldData: custInfo.emailAddr || ''}, // 이메일
              {fieldNm: 'CONT_TEL', fieldData: contTelFieldData}, // 전화번호
              {fieldNm: 'CONT_CELNO', fieldData: custInfo.celnoDash || ''}, // 휴대폰

              // 보험증권 신청내역 리스트
              {fieldNm: 'CONT_NO', fieldDataList: contNoArray, isGenString: false, dataType: 'default'}, //계약번호
              {fieldNm: 'PRDT_NM', fieldDataList: prdtNmArray, isGenString: false, dataType: 'default'}, //상품명
              {fieldNm: 'INSRD_NM', fieldDataList: insrdNmArray, isGenString: false, dataType: 'default'}, //피보험자
              {fieldNm: 'CONT_YMD', fieldDataList: contYmdArray, isGenString: false, dataType: 'default'}, //계약일자
              {fieldNm: 'SMTOT_PRM', fieldDataList: smtotPrmArray, isGenString: false, dataType: 'default'}, //보험료
              {fieldNm: 'MTH_NM', fieldDataList: uiSecusCvancMthCdNmArray, isGenString: false, dataType: 'default'}, //받는방법

              // 신청인
              {fieldNm: 'RPBL_RSN', fieldData: this.reason === '12' ? '분실' : '보장확인'},// 재발행 사유
              {fieldNm: 'APL_NM', fieldData: custInfo.custNm || ''},// 신청인 성명
              {fieldNm: 'CELNO_SELF_ATHNT_DTM', fieldData: celNoSelfAthntDtm},// 휴대폰본인인증일시

              // 접수자[컨설턴트]
              {fieldNm: 'FC_APL_YMD', fieldData: fcAplYmd},  // 신청일자(YYYY년 MM월 DD일)
              {fieldNm: 'FC_NM', fieldData: userNm || ''}, // 처리자
              {fieldNm: 'FC_DOF_FOF', fieldData: this.fn_getDofFofNm()}, // 신청기관 (지역단명 + 지점명, FC 소속 지역단명 + 지점명 구하기)
            ]
          }
        ]
      }

      return TrnsJsonCretUtil.createJsonData(fNInsrFnEltrnSignDoctVO.fNInsrFnEltrnSignDoctListVO, fNInsrFnEltrnSignDoctVO.busnScCd)
    },*/


    /******************************************************************************
      * Function명 : fn_GetJsonData
      * 설명       : PDF 에 매핑 되야 할 데이터를 JSON 형태로 만든다.
      *              asis 의 fn_callPdf 와 TrnsJsonCretUtil.createJsonData 의 기능을 풀어서 하나로 합쳤다.
    ******************************************************************************/
    fn_GetJsonData(){
      const custInfo = this.$props.custInsrdInfoVO

      let userNm = this.getStore('userInfo').getters.getUserInfo.userNm
      let birthDay = moment(this.$bizUtil.addBirthFrontYear(custInfo.knbFrno)).format('YYYY-MM-DD') || ''
      let fcAplYmd = this.today.substr(0, 4) + '년 ' + this.today.substr(4, 2) + '월 ' + this.today.substr(6, 2) + '일'
      let celNoSelfAthntDtm = this.$props.authTime.substr(0, 4) + '년 ' +
                              this.$props.authTime.substr(5, 2) + '월 ' +
                              this.$props.authTime.substr(8, 2) + '일 ' +
                              this.$props.authTime.substr(11, 5) // 휴대폰본인인증일시

      let contTelFieldData = '' //연락처
      let contAddrFieldData = '' //주소

      if (custInfo.mailRcppliScCd === '0002') { // 우편물 수령처 자택
        contTelFieldData = custInfo.homTelnoDash === null ? '' : custInfo.homTelnoDash

        if (custInfo.homeAddrScCd === '2') {
          contAddrFieldData = custInfo.homAddr + ' ' + custInfo.homebuilding + ' ' + custInfo.homDtlad + ' ' + custInfo.homestrSuppl1

        } else if (custInfo.homeAddrScCd === '1') { // 자택 구 주소인 경우
          contAddrFieldData = custInfo.homAddr + ' ' + custInfo.homDtlad
        }
      } else if (custInfo.mailRcppliScCd === '0003') { // 우편물 수령철 직장
        contTelFieldData = custInfo.jobpTelnoDash === null ? '' : custInfo.jobpTelnoDash

        if (custInfo.jobAddrScCd === '2') { // 직장이 신주소인 경우
          contAddrFieldData = custInfo.jobpAddr + ' ' + custInfo.jobpbuilding + ' ' + custInfo.jobpDtlad + ' ' + custInfo.jobpstrSuppl1

        } else if (custInfo.jobAddrScCd === '1') { // 직장 구 주소인 경우
          contAddrFieldData = custInfo.jobpAddr + ' ' + custInfo.jobpDtlad

        }
      }


      //fieldNm:fieldData
      let singleValule = {
        CRDT_ACCT_1: 'V', // 개인정보 동의수집 동의함
        CRDT_ACCT_2: 'V', // 고유식별정보 처리사항동의함
        CRDT_ACCT_3: 'V', // 고유식별정보 처리사항동의함
        CRDT_REQ_YEAR: this.today.substr(2, 2), // 신청일 년도끝자리 2자리
        CRDT_REQ_MONTH: this.today.substr(4, 2), // 신청일 월 2자리
        CRDT_REQ_DAY: this.today.substr(6, 2), // 신청일 월 2자리
        CUST_NM: custInfo.custNm || '', // 고객명

        /* 계약자 인적사항 */
        CONT_NM: custInfo.custNm || '', // 계약자명
        CONT_DOB: birthDay, // 나이 AS-IS: 생년월일
        CONT_ADDR: contAddrFieldData, // 주소 (우편물 수령처 주소)
        CONT_EMAIL: custInfo.emailAddr || '', // 이메일
        CONT_TEL: contTelFieldData, // 전화번호
        CONT_CELNO: custInfo.celnoDash || '', // 휴대폰

        /* 신청인 */
        RPBL_RSN: this.reason === '12' ? '분실' : '보장확인', // 재발행 사유
        APL_NM: custInfo.custNm || '', // 신청인 성명
        CELNO_SELF_ATHNT_DTM: celNoSelfAthntDtm, // 휴대폰본인인증일시

        /* 접수자[컨설턴트] */
        FC_APL_YMD: fcAplYmd, // 신청일자(YYYY년 MM월 DD일)
        FC_NM: userNm || '', // 처리자
        FC_DOF_FOF: this.fn_getDofFofNm(), // 신청기관 (지역단명 + 지점명, FC 소속 지역단명 + 지점명 구하기)

      }

      /* 보험증권 신청내역 리스트 fieldNm:fieldDataList */
      let gridValue = []
      let checkList = this.nBSecusRpblAplListSVO.filter(item => item.checked)
      if( checkList && checkList.length>0 ){
        for( let idx=0; idx<checkList.length; idx++ ){
          gridValue.push({
            CONT_NO: checkList[idx].contNo,
            PRDT_NM: checkList[idx].prdtNm,
            INSRD_NM: checkList[idx].insrdNm,
            CONT_YMD: checkList[idx].contYmd,
            SMTOT_PRM: checkList[idx].smtotPrm,
            MTH_NM: checkList[idx].uiSecusCvancMthCdNm
          })
        }
      }

      let jsonData = {
        servlet_addr: window.vue.config.http.baseURI + '/OZRepeaterServlet', // OZ 서블릿 경로
        FORM_ARY: [
          {'formId': '/ADD/nbCrdtInfoWcnst'},
          {'formId': '/ADD/nbInsrSecusLor'}
        ],

        //DATA_ARY부분이 asis 와 달라졌음.
        DATA_ARY: {
          SingleValueMap: singleValule,
          Array: gridValue
        },

        META_ARY: [
          {displayname: 'nbCrdtInfoWcnst',color: '',duplex: '',WTMK_NO: ''},
          {displayname: 'nbInsrSecusLor' ,color: '',duplex: '',WTMK_NO: ''}
        ]
      }

      return jsonData
    },


    /******************************************************************************
      * Function명 : fn_getDofFofNm
      * 설명       : 지역단명 + 지점명
    ******************************************************************************/
    fn_getDofFofNm () {
      let dofFofNm = '' // 리턴값

      const userInfo = this.getStore('userInfo').getters.getUserInfo

      if( this.isNotEmpty(userInfo.onpstDofNo) ) { // 지역단코드
        dofFofNm = userInfo.onpstDofNo
      }

      if( this.isNotEmpty(userInfo.onpstFofOrgNo) ){ // 지점코드
        dofFofNm += '-' + userInfo.onpstFofOrgNo
      }

      if( this.isNotEmpty(userInfo.onpstFofOrgNm) ){
        dofFofNm += '\n' + userInfo.onpstFofOrgNm
      }

      if( dofFofNm === '' ){
        dofFofNm = '-'
      }

      return dofFofNm
    },


    /******************************************************************************
    * Function명 : fn_EltrnSignDocDataSave
    * 설명       : 서명 문서에 매핑 할 데이터 저장 (전자서명양식저장)
    ******************************************************************************/
    fn_EltrnSignDocDataSave() {
      //let jsonInputData1 = this.fn_GetPdfData()
      //console.log('data1 : ', jsonInputData1)

      const custInfo = this.$props.custInsrdInfoVO
      let jsonInputData = this.fn_GetJsonData() // 문서에 매핑 되어야 할 데이터를 JSON 형태로 만드는 작업을 함.

      let params = {
        mobslElstScCd: 'K', //모바일영업전자서명구분코드 : 증권재발행 K
        contPtcpScCd: '1', //계약관계인구분코드 계약자1, 주피2, 종피3, 친권자4
        jsonInputData: JSON.stringify(jsonInputData),
        custNm: custInfo.custNm,
        custId: custInfo.custId,
        contPtcpTelnoEncr: custInfo.celno.replaceAll('-', ''),
        contPtcpRrnEncr: custInfo.rrnCipher,
        //contPtcpRrnEncr: custInfo.knbFrno.concat(custInfo.knbBkno)
      }

      let lv_Vm = this
      this.post(this, params, 'txTSSNB13I1', 'S')
        .then(function (response) {

          // 저장 결과
          if (response.body !== null && response.msgComm !== null && response.msgComm.msgCd === 'EFWS003') {
            let responseBody = response.body

            if( responseBody.result === 'success' ){
              let targetUrl = responseBody.moUrl
              console.log('targetUrl : ', targetUrl)

              //모청 호출
              if( process.env.NODE_ENV === 'local' ){
                //local pc test 하려면...
                //tss2-mws-pjt    : jetty port 1112
                //tss2-mws-ui-pjt : localhost 8081
                //tss2-mws-ui-pjt : config\index.js 파일의 port 1112 로 변경

                //tss2-pjt        : jetty port 1111
                //tss2-msp-ui-pjt : localhost 8080
                //tss2-pjt        : resources\properties\system.properties --> mws.url=http://localhost:8081/ (모청 웹 서버 port 로 변경)
                window.open(targetUrl, '_blank')
                lv_Vm.fn_GetP1Service() // 재발행신청

              } else if( lv_Vm.$commonUtil.isMobile() ){
                //{data:'success', 'fail'}

                window.fdpbridge.exec('PhoneWebViewPlugin', {uri: targetUrl}, (result) => {
                  console.log('success result : ', result)
                  
                  if( result.data === 'success' ){
                    lv_Vm.fn_GetP1Service() // 재발행신청
                  } else {
                    lv_Vm.getStore('confirm').dispatch('ADD', '전자서명 호출에 실패 했습니다. 다시 시도해 주세요.')
                  }
                }, (fResult) => {
                  console.log('fail result : ', fResult)
                  lv_Vm.getStore('confirm').dispatch('ADD', '전자서명 호출에 실패 했습니다. 다시 시도해 주세요.')

                })

              }
            } else {
              lv_Vm.getStore('confirm').dispatch('ADD', '전자서명 호출에 실패 했습니다. 다시 시도해 주세요.')
            }

          } else {
            lv_Vm.getStore('confirm').dispatch('ADD', response.msgComm.msgDesc)

          }

        }).catch(function (error) {
          window.vue.getStore('progress').dispatch('SUB')
          window.vue.error(error)

        })

    },

    /******************************************************************************
    * Function명 : fn_GetP1Service
    * 설명       : 재발행신청
    ******************************************************************************/
    fn_GetP1Service() {
      let checkList = this.nBSecusRpblAplListSVO.filter(item => item.checked)

      let params = {
        nBSecusRpblAplListSVO: checkList
      }

      console.log(params)
      
      let lv_Vm = this
      this.post(this, params, 'txTSSNB08P1', 'S')
        .then(function (response) {

          // 서버 데이터 가져오기
          if (response.body !== null && response.msgComm !== null && response.msgComm.msgCd === 'EFWS003') {
            let responseList = response.body.nBSecusRpblAplListSVO

            if (responseList !== null && responseList.length > 0) {
              lv_Vm.$emit('next', responseList) //MSPNB001M > fn_Step4Result
            }

          } else {
            lv_Vm.getStore('confirm').dispatch('ADD', response.msgComm.msgDesc)

          }

        }).catch(function (error) {
          window.vue.getStore('progress').dispatch('SUB')
          window.vue.error(error)

        })
    },


    /******************************************************************************
     * Function명 : fn_ConfirmOpen
     * 설명       : confirm창 오픈 함수
     *              confirm 창이 열릴경우 vuex에 열림 표시를 저장한다.
     ******************************************************************************/
     fn_ConfirmOpen(message) {
      let lv_AlertPop = this.$bottomModal.open(MSPBottomAlert, {
          properties: {
          //single: true,
          title: "알림",
          content: message,
          title_pos_btn: "예",
          title_neg_btn: "아니오"
        },
        listeners: {
          onPopupConfirm: () => {
            this.$emit('pre')
            this.$bottomModal.close(lv_AlertPop)
          },
          onPopupClose: () => {
            this.$bottomModal.close(lv_AlertPop)
          }
        }
      })
    },


    //개발 편의 not empty
    isNotEmpty(value) {
      return !this.$bizUtil.isEmpty(value)
    },
  }
};
</script>
<style scoped>
</style>