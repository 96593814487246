/*
 * 업무구분: 보험증권재발행
 * 화 면 명: MSPNB002D
 * 화면설명: 보험증권재발행 > STEP.1 계약자조회
 * 접근권한: 모든사용자
 * 작 성 일: 2023.01.13
 * 작 성 자: 김진원
 */
<template>
  <mo-validate-watcher ref="vWatcher">
    <!-- 컨텐츠 영역 -->
    <ur-box-container direction="column" alignV="start" class="msp-pm-wrap">
      <!-- 안내box -->
      <ur-box-container alignV="start" componentid="ur_box_container_006" direction="row" class="text-gray-box ty1 bd-T-Ty1 bd-b-Ty1">
        <span class="fs14rem crTy-bk7 ic">컨설턴트 본인 수금건 한해 신청 가능합니다.</span>
       </ur-box-container>
      <!-- 안내box// -->

      <!-- 고객정보 조회 from -->
      <ur-box-container alignV="start" componentid="ur_box_container_001" direction="column"  class="ns-add-area pt20">
        <ur-box-container alignV="start" componentid="ur_box_container_001" direction="column" class="add-box pb20">
          <span class="contain-title must">계약자</span> <!--2023-01-18수정 : contain-title 수정 //-->
          <mo-text-field class="form-input-name" @focus="fn_focus" v-model="custNm" :rules="validateRuleName" underline placeholder="고객명을 입력해주세요" maxlength="30"/>
        </ur-box-container>
        <ur-box-container alignV="start" componentid="ur_box_container_001" direction="column" class="add-box pb20">
          <div class="verify-birth">
            <mo-text-field @focus="fn_focus" v-model="knbFrno" :rules="validateRuleKnbFrno" type="number" underline class="birth-input" mask="######" placeholder="주민등록번호"/>
            <span class="hyphen ml10 mr10"></span>
            <m-trans-key-input @focus="fn_focus" v-if="$commonUtil.isMobile()" v-model="knbBknoCipher" :rules="validateRuleKnbBrno" :type="'numberMax7'" :start="'1'" :end="'-1'" @mask-type="fn_getMaskType" class="birth-input3"/>
            <mo-text-field v-else @focus="fn_focus" v-model="knbBkno" :rules="validateRuleKnbBrno" type="number" underline class="birth-input no-ic" mask="#######" placeholder="" password/>
          </div>
        </ur-box-container>
        <!-- 조회하기 완료 메시지 영역 : "ns-certify-sed" 클래스 옆에 succes 클래스 호출시 성공에 대한 메시지 호출 -->
        <div :class="{'ns-certify-sed': true, 'success': isSuccess, 'error': !isSuccess && message !== ''}">
          <ur-box-container alignV="start" componentid="ur_box_container_005" direction="column" class="ns-round-btn ns-style2 mb15">
            <mo-button class="ns-btn-round" @click="fn_custSrch" :disabled="isSrchBtnDisabled">조회하기</mo-button>
          </ur-box-container>
          <div class="ns-certify-sed-txt">
            <div :class="{'txt-success': isSuccess, 'txt-error crTy-orange2': !isSuccess}"><span class="ico"></span>{{message}}</div>
          </div>
        </div>
        <!-- 조회하기 완료 메시지 영역 //-->
      </ur-box-container>
      <!-- 고객정보 조회 from //-->

      <!-- 하단 버튼영역 -->
      <ur-box-container alignV="start" componentid="ur_box_container_009" direction="column" class="ns-btn-relative" v-if="ShowBottomBtn">
        <div class="relative-div">
          <mo-button componentid="mo_button_002" color="primary" shape="border" size="medium" class="ns-btn-round blue" :disabled="isNextBtnDisabled" @click="fn_next">다음</mo-button>
        </div>
      </ur-box-container>
      <!-- 하단 버튼영역 //-->
    </ur-box-container>
    <!-- 컨텐츠 영역 //-->
  </mo-validate-watcher>
</template>
<script>
/***********************************************************************************
 * 공통 라이브러리 INCLUDE 영역	                                                   *
 ***********************************************************************************/
import Msg from '@/systems/webkit/msg/msg'
export default {
  /***********************************************************************************
   * Vue 파일 속성 정의 영역	                                                       *
   ***********************************************************************************/
  name: "MSPNB002D",
  screenId: "MSPNB002D",
  components: {},
  /***********************************************************************************
   * Life Cycle 함수 정의 영역	                                                     *
   ***********************************************************************************/
  created() {
    let lv_Vm = this
//    lv_Vm.$BottomManager.fn_SetBottomVisible(false) // 메뉴바 숨기기
    window.vue.getWkEvtHandler().$on(Msg.DEVICE.KEYBOARD_SHOW, () => {
      // 키보드 노출시 처리
      lv_Vm.ShowBottomBtn = false
    })

    window.vue.getWkEvtHandler().$on(Msg.DEVICE.KEYBOARD_HIDE, () => {
      // 키보드 숨김시 처리
      lv_Vm.ShowBottomBtn = true
    })
  },
  destroyed () {
     // backButton event 해제
    window.vue.getWkEvtHandler().$off(Msg.DEVICE.KEYBOARD_SHOW);
    window.vue.getWkEvtHandler().$off(Msg.DEVICE.KEYBOARD_HIDE);
  },//destroyed
  mounted() {},
  /***********************************************************************************
   * 화면변수 선언 영역	                                                             *
   ***********************************************************************************/
  data() {
    return {
      nodeEnv: process.env.NODE_ENV,
      custNm: '',
      knbFrno: '',
      knbBkno: '',
      knbBknoCipher: '',
      osType: '',
      message: '',
      custInsrdInfoVO: null,
      isNextBtnDisabled: true,  //다음버튼 비활성여부
      isSrchBtnDisabled: false, //조회버튼 비활성여부
      isSuccess: false,
      ShowBottomBtn:true,
      validateRuleName: [
        (v) => !!v || '필수입력항목입니다.',
        (v) => this.$bizUtil.isCustNameValidation(this.custNm, 'ZPER02') || this.$t('nb')['ENBC036']
      ],
      validateRuleKnbFrno: [
        (v) => !!v || '필수입력항목입니다.',
        (v) => v.length === 6 || ' 앞자리를 정확히 입력해 주세요',
      ],
      validateRuleKnbBrno: [
        (v) => !!v || '필수입력항목입니다.',
        (v) => v.length === 7 || ' 뒷자리를 정확히 입력해 주세요'
      ],
    }
  },
  /***********************************************************************************
   * Computed 함수 정의 영역                                                         *
   ***********************************************************************************/
  computed: {},
  /***********************************************************************************
   * 사용자 함수 정의 영역                                                           *
   ***********************************************************************************/
  /** watch 정의 영역 */
  watch: {
    isNextBtnDisabled() {
      this.isSrchBtnDisabled = !this.isNextBtnDisabled
    }
  },
  methods: {
    /**
     * @description OS가 IOS 인 경우에는 value 가 IOS 라는 string 값이 넘어옴. android 에서는 empty 값이 넘어옴.
     *              서비스에서 IOS 인 경우에는 복호화 모듈이 달라짐. 해당 값으로 판단하여 분기 처리함.
     * @param {String} value ios : IOS, android : empty string
     */
    fn_getMaskType(value) {
      this.osType = value
    },

    /******************************************************************************
    * Function명 : fn_focus
    * 설명       : 입력필드에 focus
    ******************************************************************************/
    fn_focus() {
      this.custInsrdInfoVO = null //고객정보초기화
      this.isNextBtnDisabled = true //다음 버튼 비활성화
      this.isSuccess = false //조회성공여부 초기화
      this.message = '' //조회 결과 메시지 초기화
    },
    /******************************************************************************
    * Function명 : fn_next
    * 설명       : 다음 버튼
    ******************************************************************************/
    fn_next() {
      if( this.custInsrdInfoVO !== null ){
        this.$emit('next', this.custInsrdInfoVO)
      }
    },

    /******************************************************************************
    * Function명 : fn_custSrch
    * 설명       : 조회 버튼
    ******************************************************************************/
    fn_custSrch() {
      if(!this.$refs.vWatcher.isValid()){
        return
      }

      let pParams = {
        custNm: this.custNm, // 고객명
        knbFrno: this.knbFrno, // 주민번호 앞번호
        knbBkno: this.knbBkno, // 주민번호 뒷번호 평문
        knbBknoCipher: this.knbBknoCipher, // 주민번호 뒷 자리, 폰에서 보안키패드 인 경우 암호화 된 값이 셋팅 됨.
        osType: this.osType,
        miniYn: 'Y', // txTSSBU02D1 서비스가 mini 일 때만, 주민번호 뒷자리에 대해 ios 분기 처리 해야 해서 추가 함.
      }

      let lv_Vm = this

      //고객정보조회
      this.post(this, pParams, 'txTSSBU02D1', 'S')
        .then(function (response) {
          lv_Vm.isSuccess = false
          lv_Vm.message = ''

          if( response.body !== null ){
            if( response.body.custId.trim() === '' ){
              lv_Vm.custInsrdInfoVO = null
              lv_Vm.message = lv_Vm.$t('nb')['ENBC005'] //고객 조회 정보가 없습니다.

            } else {
              
              let lv_CustStatCd = response.body.custStatCd.trim()

              if( lv_CustStatCd === '10' ){
                let lv_CheckAge = lv_Vm.$bizUtil.compareCurrentAge(lv_Vm.knbFrno)

                if (lv_CheckAge === false) {
                  lv_Vm.message = '미성년 고객으로 신청이 불가합니다.' //lv_Vm.$t('nb')['ENBC006'] // 자녀 피보험자는 미성년자만 조회 가능합니다.
                }

              } else if (lv_CustStatCd === '11' || lv_CustStatCd === '12' || lv_CustStatCd === '13') {
                lv_Vm.message = lv_Vm.$t('nb')['ENBC007'] + '(' + response.body.custStatNm + ')' // 접수불가 고객입니다

              } else if (lv_CustStatCd === '21') {
                lv_Vm.message = lv_Vm.$t('nb')['ENBC007']

              } else if (lv_CustStatCd !== '') {
                lv_Vm.message = lv_Vm.$t('nb')['ENBC008'] // 접수불가 고객입니다(고객상태 정보 확인 불가)

              }

              if( lv_Vm.message === '' ){
                lv_Vm.isSuccess = true
                lv_Vm.isNextBtnDisabled = false
                lv_Vm.custInsrdInfoVO = response.body
                lv_Vm.custInsrdInfoVO.osType = lv_Vm.osType
                lv_Vm.message = '확인되었습니다.'
              }
            }
          } else {
            lv_Vm.message = lv_Vm.$t('nb')['ENBC009'] // 에러가 발생했습니다.
          }
        })
        .catch(function (error) {
          window.vue.error(error)
        })
    }
  }
};
</script>
<style scoped>
</style>