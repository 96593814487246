/*
 * 업무구분: 보험증권재발행
 * 화 면 명: MSPNB001M
 * 화면설명: 보험증권재발행 메인
 * 접근권한: 모든사용자
 * 작 성 일: 2022.12.23
 * 작 성 자: 김진원
 */
 <template>
  <ur-page-container v-if="currentStep < 4" class="msp" title="증권재발행" :show-title="true" type="subpage" @on-header-left-click="fn_pre" :action-type="actionSlot" :topButton="true">
    <!-- 고정영역 -->
    <template #frame-header-fixed>
      <ur-box-container direction="column" alignV="start" class="msp-pm-wrap">
        <!-- 진행단계 -->
        <ur-box-container direction="row" class="msp-step-type2 bdBN">
         <div class="step-con bdBN">
            <strong class="title">{{currentScrnName[currentStep]}}</strong>
            <span class="step-num">
              <b class="num ing">{{currentStep}}</b>
              <span class="num">4</span>
            </span> 
         </div>
        </ur-box-container>
        <!-- 진행단계 //-->
      </ur-box-container>
    </template>
    <!-- 고정영역 //-->

    <!-- STEP 시작 //-->
    <MSPNB002D v-if="currentStep === 1" @next="fn_Step1Result" @pre="fn_pre"></MSPNB002D>
    <MSPNB003D v-else-if="currentStep === 2" @next="fn_Step2Result" @pre="fn_pre" :custInsrdInfoVO="custInsrdInfoVO"></MSPNB003D>
    <MSPNB005D v-else-if="currentStep === 3" @next="fn_Step3Result" @pre="fn_pre" :custInsrdInfoVO="custInsrdInfoVO"></MSPNB005D>
    <!-- STEP 종료 //-->
  </ur-page-container>
  <!-- 4step 부터는 헤더영역이 달라서 그냥 통 페이지로 처리 -->
  <MSPNB006D v-else-if="currentStep === 4" @next="fn_Step4Result" @pre="fn_pre" :custInsrdInfoVO="custInsrdInfoVO" :authTime="authTime"></MSPNB006D>
  <MSPNB007D v-else-if="currentStep === 5" :nBSecusRpblAplListSVO="nBSecusRpblAplListSVO"></MSPNB007D>
</template>
<script>

/************************************************************************************************
* 공통 라이브러리 INCLUDE 영역
************************************************************************************************/
import MSPNB002D from '@/ui/nb/MSPNB002D' // STEP1. 계약자 조회
import MSPNB003D from '@/ui/nb/MSPNB003D' // STEP2. 본인인증 및 본인인증동의(휴대폰)
//import MSPNB004D from '@/ui/nb/MSPNB004D' //        본인인증 불가안내
import MSPNB005D from '@/ui/nb/MSPNB005D' // STEP3. 고객정보확인
import MSPNB006D from '@/ui/nb/MSPNB006D' // STEP4. 보유계약조회
import MSPNB007D from '@/ui/nb/MSPNB007D' // STEP5. 증권재발행 신청결과확인
import Msg from '@/systems/webkit/msg/msg'
import MSPBottomAlert from '@/ui/_cmm/components/MSPBottomAlert'

export default {
  /************************************************************************************************
  * Vue 파일 속성 정의 영역
  ************************************************************************************************/
  // 현재 화면명
  name: 'MSPNB001M',
  // 현재 화면 ID
  screenId: 'MSPNB001M',
  // 컴포넌트 선언
  components: {
    MSPNB002D,
    MSPNB003D,
    //MSPNB004D,
    MSPNB005D,
    MSPNB006D,
    MSPNB007D
  },
  props: {
    
  },

  /************************************************************************************************
  * 화면 전역 변수 선언 영역
  ************************************************************************************************/
  data () {
    return {
      currentScrnName: {
        1: '계약자조회',
        2: '본인인증',
        3: '고객정보확인',
        4: '보유계약조회'
      },
      currentStep: 1,
      authTime: '', // 본인인증 성공 시간
      custInsrdInfoVO: null, // 피보험자정보VO
      nBSecusRpblAplListSVO: [], //보유계약리스트
    }
  },

  /************************************************************************************************
  * Life Cycle 함수 정의 영역
  ************************************************************************************************/
  /** computed 함수 정의 영역 */
  computed: {
  },
  /** watch 정의 영역 */
  watch: {
  },
  /** 설명 : Step-2 인스턴스가 작성된 후 동기적 호출 */
  created() {
    let lv_Vm = this

    // 상단 닫기버튼 2024.06.11 상단 닫기 추가 
    this.actionSlot = {
      actionComponent: {
        template:'<mo-icon icon="close" class="fs26rem svg-closeTy1" @click="fn_MovePage">close</mo-icon>', 
        methods: {
          fn_MovePage(){
            let lv_AlertPop = lv_Vm.$bottomModal.open(MSPBottomAlert, {
                properties: {
                  title: "종료 안내",
                  content: "증권재발행 신청을 종료 하시겠습니까?<br>종료 시 작성된 내용은 저장되지 않습니다.",
                  title_pos_btn: "예",
                  title_neg_btn: "아니오"
                },
                listeners: {
                  onPopupConfirm: () => {
                    lv_Vm.$router.go(-1)
                    lv_Vm.$bottomModal.close(lv_AlertPop)
                  },
                  onPopupClose: () => {
                    lv_Vm.$bottomModal.close(lv_AlertPop)
                  }
                }
              })
          }
        }
      }
    }




    window.vue.getWkEvtHandler().$on(Msg.DEVICE.BACK_KEY, this.fn_pre)
  },

  /** 설명 : Step-7 인스턴스가 Remove 되기 전 호출 */
  destroyed() {
    window.vue.getWkEvtHandler().$off(Msg.DEVICE.BACK_KEY, this.fn_pre)
  },
  /************************************************************************************************
  * 사용자 함수 정의 영역
  ************************************************************************************************/
  methods: {
    /******************************************************************************
    * Function명 : fn_Step1Result
    * 설명       : 고객정보 조회
    ******************************************************************************/
    fn_Step1Result(data) {
      //this.$router.push( { name: 'MSPNB002D' } )
      this.custInsrdInfoVO = data
      this.currentStep++
    },
    /******************************************************************************
    * Function명 : fn_Step2Result
    * 설명       : 본인인증 && 본인인증 동의
    ******************************************************************************/
    fn_Step2Result(data) {
      this.authTime = data
      this.currentStep++
    },
    /******************************************************************************
    * Function명 : fn_Step3Result
    * 설명       : 고객정보확인
    ******************************************************************************/
    fn_Step3Result(data) {
      if( data ){ //data : step3에서 고객정보 변경 시 저장 후 고객 재 조회 한 값이 data 로 옴. 변경 안 된 경우 data 는 null
        this.custInsrdInfoVO = data
      }

      this.currentStep++

    },
    /******************************************************************************
    * Function명 : fn_Step4Result
    * 설명       : 증권재발행 신청결과 리스트
    ******************************************************************************/
    fn_Step4Result(data) {
      if( data ){
        this.nBSecusRpblAplListSVO = data // 신청 결과 리스트
      }

      this.currentStep++

    },

    fn_pre() {
      if( this.currentStep > 1){
        if (this.currentStep === 3) {
          this.fn_preConfirm()
        } else {
          this.currentStep--
        }
      } else {
        // 메뉴로 진입했으면 홈화면으로 이동한다.
        if (this.$route.query?._isMenuItem) {
          this.$router.push({name: 'MSPBC002M'})
          return
        }
        
        this.$router.go(-1)
      }
    },
    /******************************************************************************
    * Function명 : fn_pre
    * 설명       : 이전 버튼 클릭
    ******************************************************************************/
    fn_preConfirm() {
      let lv_Vm = this

      let bottomAlert = this.$bottomModal.open(MSPBottomAlert, {
          properties: {
            //single: true,
            title: "이전화면 이동 확인",
            content: '본인확인 정보가 초기화 됩니다. 이전 화면으로 이동 하시겠습니까?',
            title_pos_btn: "예",
            title_neg_btn: "아니오"
          },
          listeners: {
            onPopupConfirm: () => {
              lv_Vm.$bottomModal.close(bottomAlert)
              this.currentStep--
            },
            onPopupClose: () => {
              lv_Vm.$bottomModal.close(bottomAlert)
            }
          }
        })
    },

  },
  mounted() {
    //공통 가이드라인 : LOG 관련 화면마다 넣어 줘야 하는 script
    this.$bizUtil.insSrnLog('MSPNB001M')
  }
}
</script>