/*
 * 업무구분: 보험증권재발행
 * 화 면 명: MSPNB005D
 * 화면설명: 보험증권재발행 > STEP.3 고객정보확인
 * 접근권한: 모든사용자
 * 작 성 일: 2023.01.13
 * 작 성 자: 김진원
 */
<template>
  <mo-validate-watcher ref="vWatcher">
    <!-- 컨텐츠 영역 -->
    <ur-box-container direction="column" alignV="start" class="msp-pm-wrap">
      <!-- 계약자 정보 -->
      <ur-box-container alignV="start" componentid="ur_box_container_001" direction="column" class="ns-list-area msp-list-area pb0">  
        <ur-box-container alignV="start" componentid="ur_box_container_002" direction="column" class="ns-check-list type-2">
        <div class="list-item w100 pb0"><!-- 2023.01.31 수정 : pt40 클래스 삭제 //-->
          <div class="list-item__contents pb20 bd-b-Ty2">
            <div class="list-item__contents__title fexTy3"> <!-- 2023.01.31 수정> 'fexTy3' 추가 //-->
              <span class="name txtSkip maxW250 fexInt pr10">{{$props.custInsrdInfoVO.custNm}}</span>
              <mo-badge class="badge-sample-badge lightgreen sm" text="계약자" shape="status">계약자</mo-badge> 
            </div>
            <div class="list-item__contents__info">
              <div class="txt_box">
                <span class="crTy-bk1">{{age}}세</span><em>|</em><span class="crTy-bk1">{{rrn}}</span>
              </div>
            </div>
          </div>
        </div> 
        </ur-box-container>
      </ur-box-container>
      <!-- 계약자 정보 //-->

      <!-- 고객정보확인 -->
      <ur-box-container alignV="start" componentid="" direction="column" class="ns-add-area  pt30 mb50">
        <ur-box-container alignV="start" componentid="" direction="column" class="add-box pb30">
          <span class="contain-title must">휴대폰번호 </span>  <!--2023-01-18수정 : contain-title 수정 //-->
          <div class="verify-phone msp-verify-phone mb10">
            <msp-bottom-select :items="cmcd.ZA_CELPH_KNB_CD" v-model="celnoFNo" @focus="fn_CelnoFocus" @input="fn_CelnoBlur" underline class="mr20" placeholder="선택" bottom-title="휴대폰 번호" closeBtn scrolling/>
            <mo-decimal-field numeric v-model="celnoBNo" :rules="validateRuleCelnoBNo" @focus="fn_CelnoFocus" @blur="fn_CelnoBlur" underline :mask="celnoMasking" class="mb0 txt-center"/>
          </div>
          <div class="ns-check">
            <mo-checkbox v-model="smsReciCnsntYn" @input="fn_CelnoBlur">문자수신</mo-checkbox>
          </div> 
        </ur-box-container>  
        <ur-box-container alignV="start" componentid="" direction="column" class="add-box pb30">
          <span class="contain-title" :class="emailReciYn?'must':''">이메일 </span>
          <!-- 2023.01.13 이메일주소부분 수정 : 셀렉트박스삭제 -->
          <div class="add-box pb0 mb10">
            <mo-text-field ref="rEmail" underline v-model="emailAddr" @focus="fn_EmailFocus" @blur="fn_EmailBlur" :rules="validateRuleEmailAddr"/>
          </div>
          <div class="ns-check">
            <mo-checkbox v-model="emailReciYn" @input="fn_EmailBlur">이메일수신</mo-checkbox>
          </div> 
        </ur-box-container> 
        <ur-box-container alignV="start" componentid="" direction="column" class="add-box pb30">
          <span class="contain-title must">주소</span>  <!--2023-01-18수정 : contain-title 수정 //-->
          <ur-box-container alignV="start" componentid="" direction="column" class="ns-segment">
            <mo-segment-wrapper solid primary v-model="mailRcppliScCd" class="chip-type-wrap">
              <mo-segment-button value="0002">자택</mo-segment-button>
              <mo-segment-button value="0003">직장</mo-segment-button>
            </mo-segment-wrapper>
          </ur-box-container>
          <!-- 주소 -->
          <ur-box-container alignV="start" componentid="" direction="column" class="mt20">
            <ur-box-container alignV="start" componentid="ur_box_container_005" direction="column" class="ns-round-btn ns-style2 mb20">
              <mo-button class="ns-btn-round" @click="fn_OpenMSPCM130P">주소검색</mo-button>
            </ur-box-container>
            <ur-box-container alignV="start" componentid="" direction="column" class="add-box pb0">
              <mo-text-field type="none" underline v-model="addrInfo.addrBinding" :rules="validateRuleHomeAddrBinding" class="w50" readonly/>
            </ur-box-container> 
          </ur-box-container>
          <!-- 주소 //-->
        </ur-box-container>
        <ur-box-container alignV="start" componentid="" direction="column" class="add-box">
          <span class="contain-title" v-if="$bizUtil.isEmpty(mailRcppliScCd)">전화번호</span>
          <span class="contain-title" v-else>{{mailRcppliScCd==='0002'?'자택 전화':'직장 전화'}}</span>
          <div class="verify-phone msp-verify-phone">
            <msp-bottom-select ref="rTelFNo" :items="cmcd.ZA_TELNO_DSTRT_CD" v-model="telnoFNo" :rules="validateSelectRule" @input="fn_TelnoBlur" underline class="mr20" placeholder="선택" bottom-title="전화번호" closeBtn scrolling lazy/>
            <mo-decimal-field numeric ref="rTelBNo" v-model="telnoBNo" :rules="validateRuleTelnoBNo" @blur="fn_TelnoBlur" underline :mask="telnoMasking" class="mb0 txt-center"/>
          </div>
        </ur-box-container> 
      </ur-box-container>
      <!-- 고객정보확인 //-->

      <!-- 하단 버튼영역 -->
      <ur-box-container alignV="start" componentid="ur_box_container_002" direction="column" class="ns-btn-relative">
        <div class="relative-div">
          <mo-button componentid="mo_button_002" color="primary" shape="border" size="medium" class="ns-btn-round white" @click="fn_pre">이전</mo-button>
          <mo-button componentid="mo_button_002" color="normal" shape="border" size="medium" class="ns-btn-round blue" @click="fn_next">다음</mo-button>
        </div>
      </ur-box-container>
      <!-- 하단 버튼영역 //-->  

      <!-- 바텀시트1 : 고객정보수정안내 : 고객정보수정 될시 안내 팝업 표출 -->
      <mo-bottom-sheet ref="bottomSheet1"  class="ns-bottom-sheet closebtn">
        <template v-slot:title>고객정보수정안내</template>
       <div class="ns-btn-close" @click="close"></div>
        <div class="content-area txt-center mh50px">
          <p class="ment">
            수정된 고객정보가 있습니다.<br>
            수정된 정보는 고객카드에 반영됩니다.
          </p>
        </div>
        <template v-slot:action>
          <ur-box-container alignV="start" componentid="ur_box_container_002" direction="column" class="ns-btn-relative-bs noshadow">
            <div class="relative-div">
              <mo-button componentid="mo_button_002" color="primary" shape="border" size="medium" class="ns-btn-round blue" @click="fn_SaveCustInfo" name="확인">확인</mo-button>
            </div>
          </ur-box-container>
        </template>
      </mo-bottom-sheet>
      <!-- 바텀시트1 : 고객정보수정안내 //-->    

    </ur-box-container>
    <!-- 컨텐츠영역  //-->
  </mo-validate-watcher>
</template>
<script>
import cmConstants from '@/config/constants/cmConstants'
import MSPBottomAlert from '@/ui/_cmm/components/MSPBottomAlert'
import MSPCM130P from '@/ui/cm/MSPCM130P' // 주소검색

/***********************************************************************************
 * 공통 라이브러리 INCLUDE 영역	                                                   *
 ***********************************************************************************/
export default {
  /***********************************************************************************
   * Vue 파일 속성 정의 영역	                                                       *
   ***********************************************************************************/
  name: "MSPNB005D",
  screenId: "MSPNB005D",
  components: {},
  props:{
    custInsrdInfoVO: {type: Object}
  },
  /***********************************************************************************
   * 화면변수 선언 영역	                                                             *
   ***********************************************************************************/
  data() {
    return {
      validateRuleCelnoBNo : [
        /* 휴대폰 번호를 입력하지 않았을 경우 */
        (v) => (!!v || '휴대폰 번호를 입력 하세요'),

        (v) => (
                  /* celnoBNo를 입력 하지 않았거나 문자수신 여부를 체크하지 않으면 */
                  !v ? true : 

                  /* 010으로 시작하면 전체 11 자리이고, 010으로 시작 하지 않으면 전체 10~12자 */
                  /^010\d{8}$|^(?!010)\d{10,12}$/.test(this.celnoFNo.concat(v)) ||

                  /* 조회 값이랑 같은경우, 즉 정보를 변경하지 않은 경우
                     (개발계등 연락처 앞 자리가 temp 데이터가 들어 오는데 이 경우 010 이 아니기 때문에 예외적으로 조건 추가 함) */
                  this.celnoFNo.concat(v) === this.$props.custInsrdInfoVO.celnoDash.replaceAll('-', '') 

                || this.$t('cm')['ECMC066']), // 휴대폰 번호를 정확히 입력하여 주세요.
        () =>  !this.chkCelnoResult || this.$t('nb')['ENBC018'] //이미 사용중인 휴대폰번호 입니다.
      ],
      validateRuleEmailAddr : [
        (v) => (!this.emailReciYn ? true : !!v || '이메일 주소를 입력 하세요'),

        (v) => (
                  !v ? true :

                  /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*\.[a-zA-Z]{2,3}$/i.test(v) 
                  
                  || '이메일 주소를 정확히 입력하여 주세요.'),

        () =>  !this.chkEmailResult || this.$t('nb')[this.emailErrorMsgCd] // '이미 사용중인 이메일주소 입니다.'
      ],
      validateRuleHomeAddrBinding : [
        (v) => !!v || '주소를 입력 하세요'
      ],
      validateSelectRule: [
        (v) => {
          //선택 인 경우, 뒷번호 존재 하는 경우
          if (!this.isNotEmpty(v) && !this.$bizUtil.isEmpty(this.telnoBNo)) {
            return false
          }

          return true
        }
      ],
      validateRuleTelnoBNo : [
        (v) => {
          // 0002 : 자택, 0003 : 직장
          let message = this.$t('nb')[this.mailRcppliScCd === '0002'?'ENBC016':'ENBC017']
          let telno = this.telnoFNo.concat(v)

          //선택 인 경우, 뒷번호 존재 하는 경우
          if( this.$bizUtil.isEmpty(this.telnoFNo) && this.isNotEmpty(v) ){
            return message
          }
          //02로 시작하는데 뒷자리가 숫자 7~8 이 아닌경우
          else if( /^02(?!\d{7,8}$)/.test(telno) ){
            return message
          }
          //지역번호가 3자리인데, 전체 10~11자리 아닌경우
          else if( this.telnoFNo.length === 3 && !/^\d{10,11}$/.test(telno) ){
            return message
          }
          //지역번호가 4자리인데, 전체 11~12자리 아닌경우
          else if( this.telnoFNo.length === 4 && !/^\d{11,12}$/.test(telno) ){
            return message
          }

          return true
        }
      ],

      age : '', // step1에서 조회된 고객의 연령
      rrn : '', // step1에서 조회된 고객의 주민번호

      // 휴대폰
      celnoFNo : '010', // 핸드폰 앞자리
      celnoBNo : '', // 핸드폰 뒷번호
      celnoMasking : '', //핸드폰 마스킹
      smsReciCnsntYn : false, // 문자수신 여부 true : 1 (수신), false : 2 (수신안함)
      chkCelnoResult : false, // 휴대폰 번호 중복여부 true : 중복, false : 중복아님

      //이메일
      emailAddr : '', // 이메일 주소
      emailReciYn : false, // 이메일 수신 여부 true : 1 (수신), false : 2 (수신안함)
      chkEmailResult : false, // 이메일 중복여부 true : 중복, false : 중복아님
      emailErrorMsgCd : '', // 이메일 에러 메시지

      //주소구분 : mailRcppliScCd 와 mailFnRcppliScCd 이 같은거 같아서 일단 mailRcppliScCd 으로 통일
      mailRcppliScCd : '', // 0002 : 자택, 0003 : 직장

      //주소 상세 정보로 
      addrInfo : {
        addrScCd : '', // 자택주소 구분코드(TO-BE: 1: 지번, 2: 도로명, AS-IS: 10:지번, 20:도로명)
        pstcd : '', // 자택 우편번호
        addr : '', // 자택주소
        building : '', // 자택 건물주소
        dtlad : '', // 자택 상세주소
        strSuppl1 : '', // 자택 추가주소
        addrBinding : '', // (pstcd) addr building dtlad strSuppl1 [* 화면에 표시되는 정보 *]
        addrRefnCd : '', // 주소정제코드(01: 정제주소, 02:입력주소)
        stdztAddrYn : '' // 자택주소 표준화 여부 (01: 표준화, 02: 표준아님)
      },

      // telnoFno & telnoBNo 는 display 용 이고, 실제 서버 통신 필드는 주소가 자택 & 직장 중 선택 된 값이 기준 된다.
      telnoFNo : '', // 전화번호 앞자리
      telnoBNo : '', // 전화번호 뒷자리
      telnoMasking : '', //전화번호 마스킹

      //공통코드
      cmcd : {
        //ZA_ELTRN_MAL_DM_CD : [], // 이메일 도메인
        ZA_CELPH_KNB_CD : [], // 핸드폰
        ZA_TELNO_DSTRT_CD : [], // 전화번호 지역번호
      },
    };
  },
  /***********************************************************************************
  * Life Cycle 함수 정의 영역	                                                     *
  ***********************************************************************************/
  created() {
    this.fn_SetCustInfo() //고객정보
    this.fn_GetCommonCode() //공통코드
  },
  mounted() {},
  /***********************************************************************************
   * Watch 함수 정의 영역                                                         *
   ***********************************************************************************/
  watch: {
    // 기존우편물수령지
    mailRcppliScCd(code) {
      //주소 셋팅
      this.addrInfo = this.fn_GetHomeAddr(code) //자택 또는 직장 주소
      
      //전화번호 셋팅
      let telno = this.fn_GetHomeTel(code) //자택 또는 직장 전화번호
      if( this.isNotEmpty(telno) ){
        this.telnoFNo = /(^\d+)(.*)/.exec(telno)[1]
        this.telnoBNo = /(^\d+)(.*)/.exec(telno)[2].replaceAll('-', '')
      } else { // 빈 값인 경우
        this.telnoFNo = telno
        this.telnoBNo = telno
      }
      this.fn_TelnoMasking()

    },/*
    celnoFNo(data) {
      this.$refs.vWatcher.isValid()
    },*/
    celnoBNo() {
      this.fn_CelnoMasking()
    },
    telnoFNo() {
      this.$refs.rTelBNo.clearValidation()
    },
    telnoBNo() {
      this.$refs.rTelFNo.clearValidation()
      this.fn_TelnoMasking()
    }
  },
  /***********************************************************************************
   * Computed 함수 정의 영역                                                         *
   ***********************************************************************************/
  computed: {},
  /***********************************************************************************
   * 사용자 함수 정의 영역                                                           *
   ***********************************************************************************/
  methods: {
    close() {this.$refs.bottomSheet1.close(); },

    /******************************************************************************
    * Function명 : fn_GetCommonCode
    * 설명       : common code 가져오기 this.cmcd 의 key 값에 정의된 코드를 가져옴
    ******************************************************************************/
    fn_GetCommonCode() {
      const lv_Vm = this

      let lv_CelphCD = this.$bizUtil.cloneDeep(cmConstants.CELPH_KNB_CD)
      lv_Vm.cmcd.ZA_CELPH_KNB_CD = lv_CelphCD

      let lv_TelNoCD = this.$bizUtil.cloneDeep(cmConstants.TELNO_DSTRT_CD)
      lv_Vm.cmcd.ZA_TELNO_DSTRT_CD = lv_TelNoCD

      const custInfo = lv_Vm.$props.custInsrdInfoVO

      // 고객정보 연락처의 앞 자리가 공통코드에 없는 경우, 추가
      lv_Vm.fn_AddFirstNumber(lv_Vm.cmcd.ZA_CELPH_KNB_CD, custInfo.celnoDash) //휴대폰번호
      lv_Vm.fn_AddFirstNumber(lv_Vm.cmcd.ZA_TELNO_DSTRT_CD, custInfo.homTelnoDash) //자택번호
      lv_Vm.fn_AddFirstNumber(lv_Vm.cmcd.ZA_TELNO_DSTRT_CD, custInfo.jobpTelnoDash) //직장번호
      
      //전화번호에는 선택 추가 휴대폰번호는 문자수신 버튼이 있어서 별도 체크 안해도 되는데, 전화번호는 문자수신 버튼 기능이 없어서 추가
      lv_Vm.cmcd.ZA_TELNO_DSTRT_CD = [{value:'', text:'선택'}].concat(lv_Vm.cmcd.ZA_TELNO_DSTRT_CD)

    },


    /******************************************************************************
    * Function명 : fn_AddFirstNumber
    * param      : targetObject - this.cmcd 객체의 공통코드 항목 ex : this.cmcd.ZA_TELNO_DSTRT_CD
    *              number - dash 가 붙은 연락처 ex : 983-9044-3975
    * 설명       : 고객정보에서 불러온 연락처 앞자리가 없으면 display 용 공통코드 리스트에 추가
    ******************************************************************************/
    fn_AddFirstNumber(targetObject, number) {

      if( this.isNotEmpty(number) ){
        let dashArry = number.split('-') //ex : 983-9044-3975
        let key = targetObject.filter((item) => {return item.value === dashArry[0]})

        if( key.length === 0 ){
          targetObject.push({value: dashArry[0], text: dashArry[0]})
        }
      }
    },


    /******************************************************************************
    * Function명 : fn_SetCustInfo
    * 설명       : 고객 정보 셋팅
    ******************************************************************************/
    fn_SetCustInfo() {
      const custInfo = this.$props.custInsrdInfoVO

      // 주민번호 마스킹
      if( this.isNotEmpty(custInfo.rrn) ){
        this.rrn = custInfo.rrn.replace(/(^\d{6})(\d{1}).*/, '$1-$2******') //ex : 2301013******
      }

      this.age = this.$pmUtil.fn_GetAge(this.$bizUtil, custInfo.knbFrno) // 연령 셋팅.

      if( this.isNotEmpty(custInfo.celnoDash) ){
        let celnoDashArry = custInfo.celnoDash.split('-') //ex : 983-9044-3975
        this.celnoFNo = celnoDashArry[0] //휴대폰번호 앞자리
        this.celnoBNo = celnoDashArry[1] + celnoDashArry[2] //휴대폰번호 뒷자리
        this.fn_CelnoMasking()        
      }

      this.smsReciCnsntYn = custInfo.smsReciCnsntYn==='1' //문자수신여부

      this.emailAddr = custInfo.emailAddr //이메일
      this.emailReciYn = custInfo.emailReciYn==='1' //email 수신여부

      this.addrInfo = this.fn_GetHomeAddr(custInfo.mailRcppliScCd)

      this.mailRcppliScCd = custInfo.mailRcppliScCd //기본 우편물 수령지 0002 : 자택, 0003: 직장

      console.log(custInfo)

    },


    /******************************************************************************
    * Function명 : fn_GetHomeAddr
    * 설명       : 고객 주소 정보 셋팅
    ******************************************************************************/
    fn_GetHomeAddr(code) {

      const custInfo = this.$props.custInsrdInfoVO

      let addrInfo = {
        addrScCd : '',
        pstcd : '',
        addr : '',
        building : '',
        dtlad : '',
        strSuppl1 : '',
        addrBinding : '',
        addrRefnCd : ''
      }

      //자택 선택 시
      if( code === '0002' ){
        if ( this.isNotEmpty(custInfo.homPstcd) ) {
          
          addrInfo.addrScCd = custInfo.homeAddrScCd

          if (custInfo.homeAddrScCd === '2') { // 자택 신주소인 경우
            addrInfo.pstcd = custInfo.homPstcd
            addrInfo.addr = custInfo.homAddr
            addrInfo.building = custInfo.homebuilding
            addrInfo.dtlad = custInfo.homDtlad
            addrInfo.strSuppl1 = custInfo.homestrSuppl1

            addrInfo.addrBinding = `(${custInfo.homPstcd}) ${custInfo.homAddr} ${custInfo.homebuilding} ${custInfo.homDtlad} ${custInfo.homestrSuppl1}`

          } else if (custInfo.homeAddrScCd === '1') { // 자택 구 주소인 경우
            addrInfo.pstcd = custInfo.homPstcd
            addrInfo.addr = custInfo.homAddr
            addrInfo.dtlad = custInfo.homDtlad

            addrInfo.addrBinding = `(${custInfo.homPstcd}) ${custInfo.homAddr} ${custInfo.homDtlad}`

          }
        }
      }
      // 직장 선택시 : 0003
      else {
        if ( this.isNotEmpty(custInfo.jobpPstcd) ) {

          addrInfo.addrScCd = custInfo.jobAddrScCd
          
          if (custInfo.jobAddrScCd === '2') { // 직장이 신주소인 경우
            addrInfo.pstcd = custInfo.jobpPstcd
            addrInfo.addr = custInfo.jobpAddr
            addrInfo.building = custInfo.jobpbuilding
            addrInfo.dtlad = custInfo.jobpDtlad
            addrInfo.strSuppl1 = custInfo.jobpstrSuppl1

            addrInfo.addrBinding = `(${custInfo.jobpPstcd}) ${custInfo.jobpAddr} ${custInfo.jobpbuilding} ${custInfo.jobpDtlad} ${custInfo.jobpstrSuppl1}`

          } else if (custInfo.jobAddrScCd === '1') { // 직장 구 주소인 경우
            addrInfo.pstcd = custInfo.jobpPstcd
            addrInfo.addr = custInfo.jobpAddr
            addrInfo.dtlad = custInfo.jobpDtlad

            addrInfo.addrBinding = `(${custInfo.jobpPstcd}) ${custInfo.jobpAddr} ${custInfo.jobpDtlad}`

          }
        }
      }

      addrInfo.addrBinding = addrInfo.addrBinding.replaceAll(null, '')

      return addrInfo
    },


    /******************************************************************************
    * Function명 : fn_GetHomeTel
    * 설명       : 자택, 직장 선택에 따른 고객 전화번호 정보 셋팅
    ******************************************************************************/
    fn_GetHomeTel(code) {
      const custInfo = this.$props.custInsrdInfoVO
      let telno = ''

      //자택
      if( code === '0002' ){
        if( this.isNotEmpty(custInfo.homTelnoDash) ){
          telno = custInfo.homTelnoDash //ex : 983-9044-3975
        }
      }
      //직장 : 0003
      else {
        if( this.isNotEmpty(custInfo.jobpTelnoDash) ){
          telno = custInfo.jobpTelnoDash //ex : 983-9044-3975
        }
      }

      return telno
    },


    
    /******************************************************************************
    * Function명 : fn_CelnoFocus
    * 설명       : focus 될때, chkCelnoResult 값을 초기화 해 줘야, blur 될 때 validateRuleCelnoBNo 에 의해 중복 체크 되지 않는다.
    ******************************************************************************/
    fn_CelnoFocus() {
      this.chkCelnoResult = false
    },

    /******************************************************************************
    * Function명 : fn_CelnoBlur
    * 설명       : 휴대폰번호 뒷자리 blur event
    ******************************************************************************/
    fn_CelnoBlur() {
      this.fn_CelnoMasking()
      
      //if( this.$refs.vWatcher.isValid() ){
      this.fn_CelDupCheck()
      //}
    },


    /******************************************************************************
    * Function명 : fn_EmailFocus
    * 설명       : focus 될때, chkCelnoResult 값을 초기화 해 줘야, blur 될 때 validateRuleCelnoBNo 에 의해 중복 체크 되지 않는다.
    ******************************************************************************/
    fn_EmailFocus() {
      this.chkEmailResult = false
    },
    /******************************************************************************
    * Function명 : fn_EmailBlur
    * 설명       : 이메일 체크 및 중복여부
    ******************************************************************************/
    fn_EmailBlur() {
      this.$refs.rEmail.clearValidation()
      //if( this.$refs.vWatcher.isValid() ){
      this.fn_EmailDupCheck()
      //}
    },
    /******************************************************************************
    * Function명 : fn_TelnoBlur
    * 설명       : 전화번호 뒷자리 blur event
    ******************************************************************************/
    fn_TelnoBlur() {
      this.fn_TelnoMasking()

      //전화번호는 중복체크 안하나 봄
      //this.$refs.vWatcher.validate()
    },
    fn_CelnoMasking() {
      //휴대폰 뒷자리 마스킹
      if( this.celnoBNo.length === 8 ){
        this.celnoMasking = '#### - ####'
      } else {
        this.celnoMasking = '### - #####'
      }
    },
    fn_TelnoMasking() {
      //휴대폰 뒷자리 마스킹
      if( this.telnoBNo.length === 8 ){
        this.telnoMasking = '#### - ####'
      } else {
        this.telnoMasking = '### - #####'
      }
    },


    /******************************************************************************
    * Function명 : fn_CelDupCheck
    * 설명       : 휴대폰번호 중복체크
    ******************************************************************************/
    fn_CelDupCheck() {

      const custInfo = this.$props.custInsrdInfoVO // 조회된 고객 정보 origin 값

      let celno = this.celnoFNo.concat(this.celnoBNo) // 화면필드 바인딩 값

      // 휴대폰 번호 값이 변경되지 않은 경우 조회 하지 않음
      if( this.isNotEmpty(this.celnoBNo) && celno === custInfo.celnoDash.replaceAll('-', '') ){
        return
      }

      let params = {
        celnoDash: this.$bizUtil.telNoWithHyphen(celno),
        custNm: custInfo.custNm, // 고객 이름
        knbFrno: custInfo.knbFrno, // 주민번호 앞자리
        knbBkno: custInfo.knbBkno, // 주민번호 뒷자리평문
        knbBknoCipher: custInfo.knbBknoCipher, // 주민번호 뒷자리 암호화
        osType: custInfo.osType,
      }

      console.log('fn_CelDupCheck params : ', params)

      let lv_Vm = this
      this.chkCelnoResult = false

      this.post(this, params, 'txTSSNB13K1', 'S')
        .then(function (response) {

          console.log('휴대폰 번호 값 변경 여부 조회 결과 : ', response)

          if (response.body !== null && response.msgComm !== null && response.msgComm.msgCd === 'EFWS003') {

            lv_Vm.chkCelnoResult = response.body !== null && response.body.chkDupYn === 'Y'
            
            //true 이면 이미 사용중인 핸드폰.
            if( lv_Vm.chkCelnoResult ){
              lv_Vm.$refs.vWatcher.validate()
            }
          }
        })
        .catch(function (error) {
          window.vue.error(error)
        })
    },


    /******************************************************************************
    * Function명 : fn_DuplicationEmailChk
    * 설명       : 이메일 중복체크
    ******************************************************************************/
    fn_EmailDupCheck() {
      //이메일 체크 부터 하면 됨.

      const custInfo = this.$props.custInsrdInfoVO // 조회된 고객 정보 origin 값

      // 이메일이 변경 되지 않았다면 조회 하지 않음
      if( this.emailAddr === custInfo.emailAddr ){
        return
      }

      let params = {
        custId: custInfo.custId, // 고객ID
        custEmailAddr: this.emailAddr,
        custNm: custInfo.custNm, // 고객 이름
        knbFrno: custInfo.knbFrno, // 주민번호 앞자리
        knbBkno: custInfo.knbBkno, // 주민번호 뒷자리평문
        knbBknoCipher: custInfo.knbBknoCipher, // 주민번호 뒷자리 암호화
        customerType: custInfo.customerType // 주고객이 기존, 신규인지 여부
      }

      console.log('fn_EmailDupCheck params : ', params)

      let lv_Vm = this

      this.chkEmailResult = false
      this.emailErrorMsgCd = ''

      this.post(this, params, 'txTSSFN01K2', 'S')
        .then(function (response) {

          if (response.body !== null && response.msgComm !== null && response.msgComm.msgCd === 'EFWS003') {

            if( response.body !== null && response.body.chkDupYn === 'Y' ){
              lv_Vm.chkEmailResult = true
              lv_Vm.emailErrorMsgCd = 'ENBC019' // '이미 사용중인 이메일주소 입니다.'
              lv_Vm.$refs.vWatcher.validate()
            }

          }
          else if( lv_Vm.isNotEmpty(response.msgComm) && lv_Vm.isNotEmpty(response.msgComm.msgDesc) && response.msgComm.msgCd === 'EFNU001' || response.msgComm.msgCd === 'EFNU002' ){

            lv_Vm.getStore('confirm').dispatch('ADD', response.msgComm.msgDesc)
            lv_Vm.chkEmailResult = true
            lv_Vm.emailErrorMsgCd = 'ENBC039' // '이메일을 변경하여 입력하세요.'
            lv_Vm.$refs.vWatcher.validate()

          }
        })
        .catch(function (error) {
          window.vue.error(error)
        })
    },


    /******************************************************************************
    * Function명 : fn_pre
    * 설명       : 이전 버튼 클릭
    ******************************************************************************/
    fn_pre() {
      //[ASR240600536] 24-07-09,  "상단 백키(<) 을 눌러 이전으로 갈 시 이전화면 이동 확인버튼 생성" 요청에 따른 MSPBottomAlert 관련 처리 MSPNB001M에서 처리(fn_preConfirm)
      let lv_Vm = this
      lv_Vm.$emit('pre')
      // let lv_Vm = this

      // let bottomAlert = this.$bottomModal.open(MSPBottomAlert, {
      //     properties: {
      //       //single: true,
      //       title: "이전화면 이동 확인",
      //       content: '본인확인 정보가 초기화 됩니다. 이전 화면으로 이동 하시겠습니까?',
      //       title_pos_btn: "예",
      //       title_neg_btn: "아니오"
      //     },
      //     listeners: {
      //       onPopupConfirm: () => {
      //         lv_Vm.$bottomModal.close(bottomAlert)
      //         lv_Vm.$emit('pre')
      //       },
      //       onPopupClose: () => {
      //         lv_Vm.$bottomModal.close(bottomAlert)
      //       }
      //     }
      //   })
    },

    /******************************************************************************
    * Function명 : fn_next
    * 설명       : 다음 버튼 클릭
    ******************************************************************************/
    fn_next() {
      // true : 오류 없음, false : 오류 있음
      if( !this.$refs.vWatcher.isValid() ){
        return
      }

      // 최초 조회된 값과 현재 필드값 비교 : true 같음, false 다름
      if( !this.fn_CompareValue() ){
        this.$refs.bottomSheet1.open()
        return
      }

      // 오류도 없고, 변경된 값도 없으면 그냥 null 셋팅. MSPNB001M 에서 null 인경우 최초 조회된 고객 정보를 그대로 사용함.
      this.$emit('next', null) //MSPNB001M > fn_Step3Result
    },


    /******************************************************************************
    * Function명 : fn_CompareValue
    * 설명       : 고객 정보 변경 사항 확인
    ******************************************************************************/
    fn_CompareValue() {
      // 입력값
      const fieldValues = {
        celno: this.celnoFNo.concat(this.celnoBNo),
        smsReciCnsntYn: this.smsReciCnsntYn?'1':'2',
        emailAddr: this.emailAddr,
        emailReciYn: this.emailReciYn?'1':'2',
        mailRcppliScCd: this.mailRcppliScCd,
        addrBinding: this.addrInfo.addrBinding,
        telno: this.telnoFNo.concat(this.telnoBNo)
      }

      const custInfo = this.$props.custInsrdInfoVO // 초기 조회값
      const orgAddrInfo = this.fn_GetHomeAddr(custInfo.mailRcppliScCd)

      const orginValues = {
        celno: this.isNotEmpty(custInfo.celno) ? custInfo.celno.replaceAll('-', '') : '',
        smsReciCnsntYn: this.isNotEmpty(custInfo.smsReciCnsntYn) ? custInfo.smsReciCnsntYn : '2',
        emailAddr: custInfo.emailAddr,
        emailReciYn: this.isNotEmpty(custInfo.emailReciYn) ? custInfo.emailReciYn : '2',
        mailRcppliScCd: custInfo.mailRcppliScCd,
        addrBinding: orgAddrInfo.addrBinding,
        telno: this.fn_GetHomeTel(custInfo.mailRcppliScCd).replaceAll('-', '')
      }

      //console.log('compare field value : ', JSON.stringify(fieldValues))
      //console.log('compare orgin value : ', JSON.stringify(orginValues))

      return JSON.stringify(fieldValues) === JSON.stringify(orginValues)

    },


    /******************************************************************************
    * Function명 : fn_SaveCustInfo
    * 설명       : 입력된 고객정보 저장
    ******************************************************************************/
    fn_SaveCustInfo() {
      const custInfo = this.$props.custInsrdInfoVO // 초기 조회값

      let celnoDash = this.$bizUtil.telNoWithHyphen(this.celnoFNo.concat(this.celnoBNo)) // 휴대폰번호 '-'포함
      let telnoDash = this.$pmUtil.homeTelNoWithHyphenPlanText(this.telnoFNo.concat(this.telnoBNo)) // 전화번호 '-'포함
      //telnoDash 가 조회때는 dash 가 붙는데, 수정할 때 셋팅 되는 param 에는 dash 가 붙으면 안되는 듯.

      console.log('osType : ', custInfo.osType)

      let params = {}
      params.miniYn = 'Y'
      params.osType = custInfo.osType
      params.busnScCd = 'NB' // 업무구분
      params.fnJobInfoYn = 'N' // 융자직장정보 포함여부 (포함:Y, 미포함: N or '')
      params.custNm = custInfo.custNm // 고객이름
      params.knbFrno = custInfo.knbFrno // 주민번호 앞번호
      params.knbBknoCipher = custInfo.knbBknoCipher // 암호화된 주민번호 뒷번호
      params.knbBkno = custInfo.knbBkno // 주민번호 뒷번호 평문
      params.custId = custInfo.custId // 고객ID
      params.category = custInfo.category // 개인(1) / 법인(2) 구분
      params.natyCd = custInfo.natyCd // 국가코드
      params.mailRcppliScCd = this.mailRcppliScCd // 자택: 0002, 직장: 0003
      
      // 문자수신 체크 해제 상태에서 입력 정보 없는 경우, 기존 정보 유지 (asis 방식)
      if( this.isNotEmpty(this.celnoBNo) ){
        params.celnoDash = celnoDash
        params.celno = this.celnoFNo.concat(this.celnoBNo)
      } else {
        params.celnoDash = custInfo.celnoDash
        params.celno = custInfo.celno
      }

      params.emailAddr = this.emailAddr // 이메일주소
      params.smsReciCnsntYn = this.smsReciCnsntYn?'1':'2' // 문자수신여부
      params.emailReciYn = this.emailReciYn?'1':'2' // 이메일수신여부

      // 우편물 수령지가 자택이면 자택 필드에 addrInfo 를 셋팅, 직장필드는 원본값으로 셋팅
      if( this.mailRcppliScCd === '0002' ) {
        params.homeAddrScCd = this.addrInfo.addrScCd // 자택주소코드
        params.homPstcd = this.addrInfo.pstcd // 자택 우편번호
        params.homAddr = this.addrInfo.addr // 자택주소
        params.homebuilding = this.addrInfo.building // 자택 건물주소
        params.homDtlad = this.addrInfo.dtlad // 자택 상세주소
        params.homestrSuppl1 = this.addrInfo.strSuppl1 // 자택 추가주소
        params.homAddrRefnCd = this.addrInfo.addrRefnCd // 주소정제코드
        params.homStdztAddrYn = this.addrInfo.stdztAddrYn // 자택주소표준화여부
        params.homTelno = this.telnoFNo.concat(this.telnoBNo)
        params.homTelnoDash = telnoDash

        params.jobAddrScCd = custInfo.jobAddrScCd // 직장주소코드
        params.jobpPstcd = custInfo.jobpPstcd // 직장 우편번호
        params.jobpAddr = custInfo.jobpAddr // 직장주소
        params.jobpbuilding = custInfo.jobpbuilding // 직장건물주소
        params.jobpDtlad = custInfo.jobpDtlad // 직장상세주소
        params.jobpstrSuppl1 = custInfo.jobpstrSuppl1 // 직장추가주소
        params.jobAddrRefnCd = custInfo.jobAddrRefnCd // 주소정제코드
        params.jobStdztAddrYn = custInfo.jobStdztAddrYn // 직장주소표준화여부
        params.jobpTelno = custInfo.jobpTelno // 직장 전화번호
        params.jobpTelnoDash = custInfo.jobpTelnoDash // 직장전화번호'-'포함

      }
      // 우편물 수령지가 직장이면 직장 필드에 addrInfo 값으로 셋팅
      else {
        //params.homeAddrScCd = custInfo.homeAddrScCd // 자택주소코드
        params.homPstcd = custInfo.homPstcd // 자택 우편번호
        params.homAddr = custInfo.homAddr // 자택주소
        params.homebuilding = custInfo.homebuilding // 자택 건물주소
        params.homDtlad = custInfo.homDtlad // 자택 상세주소
        params.homestrSuppl1 = custInfo.homestrSuppl1 // 자택 추가주소
        params.homAddrRefnCd = custInfo.homAddrRefnCd // 주소정제코드
        //params.homStdztAddrYn = custInfo.homStdztAddrYn // 자택주소표준화여부
        params.homTelno = custInfo.homTelno
        params.homTelnoDash = custInfo.homTelnoDash
        
        params.jobAddrScCd = this.addrInfo.addrScCd // 직장주소코드
        params.jobpPstcd = this.addrInfo.pstcd // 직장 우편번호
        params.jobpAddr = this.addrInfo.addr // 직장주소
        params.jobpbuilding = this.addrInfo.building // 직장건물주소
        params.jobpDtlad = this.addrInfo.dtlad // 직장상세주소
        params.jobpstrSuppl1 = this.addrInfo.strSuppl1 // 직장추가주소
        params.jobAddrRefnCd = this.addrInfo.addrRefnCd // 주소정제코드
        params.jobStdztAddrYn = this.addrInfo.stdztAddrYn // 직장주소표준화여부
        params.jobpTelno = this.telnoFNo.concat(this.telnoBNo) // 직장전화번호'-'포함
        params.jobpTelnoDash = telnoDash // 직장전화번호'-'포함

      }

      let lv_Vm = this

      this.post(lv_Vm, params, 'txTSSNB01U1', 'S')
        .then(function (response) {
          lv_Vm.$refs.bottomSheet1.close()

          // 서버 데이터 가져오기
          if (response.body !== null && response.msgComm !== null && response.msgComm.msgCd === 'EFWS003') {
            lv_Vm.fn_CustInfoSearch()

          } else {
            lv_Vm.getStore('confirm').dispatch('ADD', response.msgComm.msgDesc)

          }
        })
        .catch(function (error) {
          window.vue.error(error)
        })
    },


    /******************************************************************************
    * Function명 : fn_CustInfoSearch
    * 설명       : 고객조회
    ******************************************************************************/
    fn_CustInfoSearch () {
      const custInfo = this.$props.custInsrdInfoVO // 초기 조회값

      /*
      let params = {
          custNm: custInfo.custNm, // 고객명
          knbFrno: custInfo.knbFrno, // 주민번호 앞번호
          knbBknoCipher: custInfo.knbBknoCipher, // 주민번호 뒷번호
          knbBkno: custInfo.knbBkno, // 주민번호 뒷번호 평문
          miniYn: 'Y', // txTSSBU02D1 서비스가 mini 일 때만, 주민번호 뒷자리에 대해 ios 분기 처리 해야 해서 추가 함.
      }*/

      let params = {
          custId: custInfo.custId, // 고객ID 로 조회
      }

      let lv_Vm = this

      this.post(lv_Vm, params, 'txTSSBU02D1', 'S')
        .then(function (response) {
          // 서버 데이터 가져오기
          if (response.body !== null) {
            if (response.body.custId.trim() === '') {
              // 이름과 식별번호가 일치하지 않는 케이스 처리
              lv_Vm.fnCustInfoSVO = {}
              lv_Vm.getStore('confirm').dispatch('ADD', this.$t('nb')['ENBC005']) // 고객 조회 정보가 없습니다.
              
            } else {
              // 고객 정보 상태 체크
              let lv_CustStatCd = response.body.custStatCd.trim()
              let lv_ErrMsg = ''
              if (lv_CustStatCd === '10') {
                lv_ErrMsg = ''
                let lv_CheckAge = lv_Vm.$bizUtil.compareCurrentAge(custInfo.knbFrno)
                if (lv_CheckAge === false) {
                  lv_ErrMsg = this.$t('nb')['ENBC006'] // '자녀 피보험자는 미성년자만 조회 가능합니다.'
                }
              } else if (lv_CustStatCd === '11' || lv_CustStatCd === '12' || lv_CustStatCd === '13') {
                lv_ErrMsg = this.$t('nb')['ENBC007'] + '(' + response.body.custStatNm + ')'
              } else if (lv_CustStatCd === '21') {
                lv_ErrMsg = this.$t('nb')['ENBC007']
              } else if (lv_CustStatCd !== '') {
                lv_ErrMsg = this.$t('nb')['ENBC008'] // 접수불가 고객입니다(고객상태 정보 확인 불가)'
              }

              if (lv_ErrMsg !== '') {
                lv_Vm.getStore('confirm').dispatch('ADD', lv_ErrMsg)
              } else {
                //txTSSBU02D1 서비스가 custId 로 조회 하는 경우에는 리턴 값에 knbFrno, knbBkno 을 되돌려 주지 않음으로 재 셋팅.
                response.body.osType = custInfo.osType
                response.body.miniYn = custInfo.miniYn
                response.body.knbFrno = custInfo.knbFrno
                response.body.knbBkno = custInfo.knbBkno
                // 정상조회처리
                lv_Vm.$emit('next', response.body)
              }
            }
          } else {
            // 에러 메시지 발생
            lv_Vm.getStore('confirm').dispatch('ADD', this.$t('nb')['ENBC009'])
          }
        })
        .catch(function (error) {
          window.vue.error(error)
        })
    },



    /*********************************************************
     * Function명: fn_OpenMSPCM130P
     * 설명 : 주소검색 팝업 열기
     *
     *********************************************************/
    fn_OpenMSPCM130P() {  
      let lv_Vm = this
      
      let isShowAddrPopUp = this.$bottomModal.open(MSPCM130P, {
        properties: {
        },
        listeners: {
          onPopupClose: (result) => {
            if( result.selectedType === 'road' ) {
              lv_Vm.addrInfo.addrScCd = '2'
              lv_Vm.addrInfo.addrRefnCd = result.addrRefnCd // 01 주소정제코드(01: 정제주소, 02:입력주소)
              lv_Vm.addrInfo.pstcd = result.pstCd // 자택 우편번호 : 03923
              lv_Vm.addrInfo.addr = result.basAddr // 자택주소 : 서울 마포구 성암로
              lv_Vm.addrInfo.dtlad = result.roadNmDtlad // 자택 상세주소 : 한국지역정보개발원
              lv_Vm.addrInfo.building = result.bldgBonbHo // 자택 건물주소 : 301
              lv_Vm.addrInfo.strSuppl1 = result.roadNmAddmAddr // 자택 추가주소 : (상암동)

            } else { // input
              lv_Vm.addrInfo.addrScCd = '1'
              lv_Vm.addrInfo.addrRefnCd = result.addrRefnCd // 02 주소정제코드(01: 정제주소, 02:입력주소)
              lv_Vm.addrInfo.pstcd = result.pstCd // 자택 우편번호 : 03923
              lv_Vm.addrInfo.addr = result.basAddr // 자택주소 : 서울특별시 마포구 성암로
              lv_Vm.addrInfo.dtlad = result.roadNmDtlad // 자택 상세주소 : 301 한국지역정보개발원(KLID Tower)
              //lv_Vm.addrInfo.building = result.bldgBonbHo // 자택 건물주소 : ''
              //lv_Vm.addrInfo.strSuppl1 = result.roadNmAddmAddr // 자택 추가주소 : ''

            }

            lv_Vm.addrInfo.stdztAddrYn = '' // 자택주소 표준화 여부 (01: 표준화, 02: 표준아님)

            // selectedType 이 road 또는 input 인 경우에 대한 각각의 주소
            // road : (03923) 서울 마포구 성암로 301 한국지역정보개발원 (상암동)
            // input : (03923) 서울특별시 마포구 성암로 301 한국지역정보개발원(KLID Tower)
            lv_Vm.addrInfo.addrBinding = `(${result.pstCd}) ${result.basAddr} ${result.bldgBonbHo} ${result.roadNmDtlad} ${result.roadNmAddmAddr}`

            lv_Vm.$bottomModal.close(isShowAddrPopUp)

          }
        }
      })
    },

    //개발 편의 not empty
    isNotEmpty(value) {
      return !this.$bizUtil.isEmpty(value)
    },
  }
}
</script>
<style scoped>
</style>